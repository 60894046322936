import React from "react";

import { Tab, Tabs } from "@material-ui/core";

import { useSelector } from "react-redux";
import { useAuth } from "@hooks/useAuth";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import usePersistedState from "@hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import useTranslation from "src/translations/useTranslation";
import { HardwareModuleMessages, MenuMessages } from "@shared/languages/interfaces";
import Header from "../../../components/Header";
import TabPanel from "../../../components/TabPanel";
import CommandsList from "./CommandsList";
import HardwaresList from "./HardwaresList";
import InstallationHistoryList from "./InstallationHistoryList";
import LogViewer from "./LogViewer";
import MaintenancesHistory from "./MaintenancesHistory";

import { Container } from "./styles";

const Hardwares: React.FC = () => {

  const [selectedTab, setSelectedTab] = React.useState(0);
  const { screen } = useSelector((state) => state);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  const { user } = useAuth();

  const tabs = user.super_admin
    ? [
      { label: t(HardwareModuleMessages.tabsMaintenanceHistoru), component: <MaintenancesHistory /> },
      { label: t(HardwareModuleMessages.tabsHardware), component: <HardwaresList /> },
      { label: t(HardwareModuleMessages.tabsHardwareInstallationHistory), component: <InstallationHistoryList /> },
      { label: t(HardwareModuleMessages.tabsCommands), component: <CommandsList /> },
      { label: t(HardwareModuleMessages.tabsLogs), component: <LogViewer /> }
    ]
    : [{ label: t(HardwareModuleMessages.tabsMaintenanceHistoru), component: <MaintenancesHistory /> }];

  return (
    <>
      <Header title={t(MenuMessages.hardwares)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          style={{ marginTop: "-20px" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} disableRipple />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={selectedTab} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Container>
    </>
  );
};

export default Hardwares;
