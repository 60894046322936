import React,  { useState } from "react";
import { useSelector } from "react-redux";
// region Imports - Libraries
// endregion Imports - Libraries

// region Imports - Utils
// endregion Imports - Utils

// region Imports - Material-UI
// endregion Imports - Material-UI

// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
// endregion Imports - Languages

// region Imports - Shared
import {
  DataTableMessages, HardwareModuleMessages,
  MaintenancesModuleMessages, VehiclesHistoryMessages, VehiclesModuleMessages
} from "@shared/languages/interfaces";
// endregion Imports - Shared

// region Imports - Components
import Header from "@components/Header";
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import QueryFilterMaintenanceHistory from "@organisms/QueryFilterMaintenanceHistory";
import { MaintenanceHistory } from "@shared/interfaces/maintenance-history.inteface";
// endregion Imports - Components

// region Imports - Services
// endregion Imports - Services

// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store

// region Imports - Styles
import utils from "@utils/useful-functions";
import { format } from "date-fns";
import * as dateFns from "date-fns";
import { Container } from "./styles";
// endregion Imports - Styles;

const MaintenancesHistory: React.FC = () => {
  /* region States */
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);
  const [dataTableValues, setDataTableValues] = useState<MaintenanceHistory[]>([] as Array<MaintenanceHistory>);
  /* endregion States */

  /* region Hooks */
  const { t } = useTranslation();
  const { screen } = useSelector((screen) => screen);
  /* endregion Hooks */

  /* region Constants */
  // Create config to specific state
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "desc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableColumns : DataTableColumns[] = [
    { // occurrence_date
      title: t(MaintenancesModuleMessages.occurrenceDate),
      data: (data: MaintenanceHistory) => (data.occurrence_date ?? ""),
      filterable: true,
      propertyName: "maintenance.occurrenceDate",
      render: (data) => (data ? (dateFns.format(new Date(data), "dd/MM/yyyy")) : "")

    },
    { // Regional
      title: t(VehiclesModuleMessages.regional),
      data: (data: MaintenanceHistory) => (data.vehicle?.location?.regional?.name ?? ""),
      filterable: true,
      propertyName: "maintenance.regional"
    },
    { // Central
      title: t(VehiclesModuleMessages.plant),
      data: (data: MaintenanceHistory) => (data.vehicle?.location?.name ?? ""),
      filterable: true,
      propertyName: "maintenance.plant"
    },
    { // zendeskTickets
      title: t(MaintenancesModuleMessages.zendeskTicket),
      data: (data: MaintenanceHistory) => (data.service_request ?? ""),
      filterable: true,
      propertyName: "maintenance.zendeskTickets"
    },
    { // source_vehicle_code
      title: t(MaintenancesModuleMessages.vehicleType),
      data: (data: MaintenanceHistory) => (data.vehicle?.type?.description ?? ""),
      filterable: true,
      propertyName: "maintenance.vehicleType"
    },
    { // source_vehicle_code
      title: t(MaintenancesModuleMessages.vehicleCode),
      data: (data: MaintenanceHistory) => (data.vehicle?.code ?? data.source_vehicle_code),
      filterable: true,
      propertyName: "maintenance.vehicleCode"
    },
    { // Placa
      title: t(VehiclesHistoryMessages.licensePlate),
      data: (data: MaintenanceHistory) => (data.vehicle?.license_plate ?? ""),
      filterable: true,
      propertyName: "maintenance.licensePlate"
    },
    { // Dev ID
      title: t(HardwareModuleMessages.devId),
      data: (data: MaintenanceHistory) => (data.vehicle?.hardwares[0].dev_id ?? ""),
      filterable: true,
      propertyName: "maintenance.problemType"
    },
    { // note
      title: t(HardwareModuleMessages.hardwareType),
      data: (data: MaintenanceHistory) => (data.hardware_type ?? ""),
      filterable: true,
      propertyName: "maintenance.note"
    },
    { // diagnosis
      title: t(MaintenancesModuleMessages.diagnostic),
      data: (data: MaintenanceHistory) => (data.diagnostic ?? ""),
      filterable: true,
      propertyName: "maintenance.diagnosis"
    },
    { // note
      title: t(MaintenancesModuleMessages.note),
      data: (data: MaintenanceHistory) => (data.note ?? ""),
      filterable: true,
      propertyName: "maintenance.note",
      className: "minWidthNoteColumn"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_manutencoes_${format(new Date(), "dd-MM-yyyy")}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: MaintenanceHistory[]
  ) => {
    setDataTableValues(returnedData);
    setNumberOfFilteredOptions(numberOfFilteredOptions);
  };

  /* endregion  Functions */

  return (
    <>
      <Header title={t(MaintenancesModuleMessages.maintenanceHistory)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <NewDataTable
          title={t(MaintenancesModuleMessages.maintenanceHistory)}
          filters
          data={dataTableValues}
          columns={dataTableColumns}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
      </Container>
      <QueryFilterMaintenanceHistory
        open={openFilterFields}
        onClose={() => setOpenFilterFields(false)}
        onFilter={handleApplyFilter}
      />
    </>
  );
};

export default MaintenancesHistory;
