/* eslint-disable no-param-reassign, no-underscore-dangle */

import React, { useState } from "react";
import { useSelector } from "react-redux";

// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  TravelHistoryMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import {
  BasePumpTravelReport,
  PumpWithMixers,
  RelatedMixerTravelReport
} from "@shared/interfaces/pump-travel-report.interface";
// endregion Imports - Shared
// region Imports - Components
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import { IDateRangeValues } from "@organisms/GenericQueryFilter";
import MixersRelatedDetailsModal from "@components/Modal/PumpReportDetails";
import QueryFilterPumpTravelReport from "@organisms/QueryFilterPumpTravelReport";

// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import { Container } from "./styles";
import ReactDOMServer from "react-dom/server";
import { Grid } from "@material-ui/core";
import ButtonTable from "@components/Button/ButtonTable";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
// endregion Imports - Styles

interface TravelPumpReportProps {
  defaultDateRange?: IDateRangeValues;
}

const TravelPumpReport: React.FC<TravelPumpReportProps> = ({ defaultDateRange }) => {

  // region Hooks
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  // endregion Hooks

  // region States
  const [loading, setLoading] = useState(false);
  const [relatedMixers, setRelatedMixers] = useState<RelatedMixerTravelReport[]>([]);
  const [titleRelatedMixers, setTitleRelatedMixers] = useState<string>("");
  const [openRelatedMixersModal, setOpenRelatedMixersModal] = useState(false);

  const [pumpTravelReport, setPumpTravelReport] = useState<BasePumpTravelReport[]>([]);

  // Filter states
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  // endregion States

  // region Functions

  /**
   * Apply the filter to the data and update the table
   */
  const handleApplyFilter = (numberOfFilteredOptions: number, travelAnalyticsData: PumpWithMixers[]) => {

    setLoading(true);

    try {
      setNumberOfFilteredOptions(numberOfFilteredOptions);
      setPumpTravelReport(travelAnalyticsData);
    } catch (error) {

      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoading(false);
    }
  };

  // endregion Functions

  // region DataTable
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableColumns: DataTableColumns[] = [
    { // Start Date
      title: t(GlobalMessages.date),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.startDate,
      filterable: false
    },
    { // Client
      title: t(GlobalMessages.vehicle),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.pumpCode,
      filterable: false

    },
    { // Client
      title: t(TravelHistoryMessages.client),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.clientName ?? "",
      filterable: false
    },
    { // Driver
      title: t(GlobalMessages.driver),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.driverName ?? "",
      filterable: false
    },
    { // Construction Entry Date
      title: t(TravelHistoryMessages.constructionEntryDate),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.constructionEntryDate ?? "",
      filterable: false
    },
    { // Construction Leave Date
      title: t(TravelHistoryMessages.constructionLeaveDate),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.constructionLeaveDate ?? "",
      filterable: false
    },
    { // Duration on Construction
      title: t(TravelHistoryMessages.durationOnConstruction),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.durationOnConstruction ?? "",
      filterable: false
    },
    { // Total Pumping Duration
      title: t(TravelHistoryMessages.totalPumpingDuration),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.totalPumpingDuration,
      filterable: false
    },
    { // Total Idle Duration
      title: t(TravelHistoryMessages.totalIdleDuration),
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.totalIdleDuration,
      filterable: false
    },{ // Alerts
      title: "Alertas",
      className: "print csv",
      data: (record: BasePumpTravelReport) => record.alerts ?? "",
      filterable: false
    },

    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button pump-history-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".pump-history-details",
      callback: (rowData: PumpWithMixers)  => handlePumpReportDetails(rowData)
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: "relatorio_viagens_bomba",
      exportOptions: {
        columns: "th:not(:last-child)"
      },
      customize: () => {

        let result = pumpTravelReport.map((row) => {

          const pumpHeader = dataTableColumns.slice(0, -1);
          const pumpHeaderTitles = pumpHeader.map(column => column.title).join(";");

          const mixerHeader = [
            `${t(TravelHistoryMessages.mixerVehicleCode)}`,
            `${t(TravelHistoryMessages.documentNumber)}`,
            `${t(TravelHistoryMessages.startDateTransport)}`,
            `${t(TravelHistoryMessages.endDateTransport)}`,
            `${t(TravelHistoryMessages.durationTransport)}`,
            `${t(TravelHistoryMessages.startDateLocation)}`,
            `${t(TravelHistoryMessages.endDateLocation)}`,
            `${t(TravelHistoryMessages.durationLocation)}`,
            `${t(TravelHistoryMessages.startDateUnload)}`,
            `${t(TravelHistoryMessages.endDateUnload)}`,
            `${t(TravelHistoryMessages.durationUnload)}`,
            `${t(TravelHistoryMessages.startDateReturn)}`
          ].join(";");

          const pumpData = [
            row.startDate,
            row.pumpCode,
            row.clientName,
            row.driverName,
            row.constructionEntryDate,
            row.constructionLeaveDate,
            row.durationOnConstruction,
            row.totalPumpingDuration,
            row.totalIdleDuration,
            row.alerts
          ].join(";");

          // @ts-ignore
          const mixerTravelsData = row.relatedMixerTravel.length > 0  ? row.relatedMixerTravel.map(mixer => [
            mixer.mixerTravelVehicleCode,
            mixer.mixerTravelNumDoc,
            mixer.mixerTravelOnTheWayStartDate,
            mixer.mixerTravelOnTheWayEndDate,
            mixer.mixerTravelOnTheWayDuration,
            mixer.mixerTravelOnConstructionStartDate,
            mixer.mixerTravelOnConstructionEndDate,
            mixer.mixerTravelOnConstructionDuration,
            mixer.mixerTravelDischargingStartDate,
            mixer.mixerTravelDischargingEndDate,
            mixer.mixerTravelDischargingDuration,
            mixer.mixerTravelReturningStartDate
          ].join(";")).join("\n")
            : "";

          const data = [
            pumpHeaderTitles,
            pumpData,
            mixerHeader,
            mixerTravelsData
          ].filter(Boolean).join("\n");

          return data;
        }).join("\n\n");

        return result;
      }
    }
  ];
  // endregion DataTable

  /**
   * Handle the pump details
   */
  const handlePumpReportDetails = async (pump: BasePumpTravelReport) => {

    if (pump.relatedMixerTravel && (pump.relatedMixerTravel.length >= 1 && pump.relatedMixerTravel[1]?.mixerTravelVehicleCode)) {
      setRelatedMixers(pump.relatedMixerTravel);
      setTitleRelatedMixers(pump.pumpCode ?? "");
      setOpenRelatedMixersModal(true);
    } else {
      addToast({ type: "info", title: t(TravelHistoryMessages.noMixerRelated) });

      return;
    }
  };

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        {!loading && (
          <NewDataTable
            title={t(TravelHistoryMessages.pumpReportTitle)}
            filters
            data={pumpTravelReport}
            columns={dataTableColumns}
            actions={dataTableActions}
            settings={dataTableSettings}
            buttons={dataTableButtons}
            onClickFilterButton={() => setOpenFilterFields(true)}
            numberOfFilteredOptions={numberOfFilteredOptions}
          />
        )}
        {
          openRelatedMixersModal && (
            <>
              <MixersRelatedDetailsModal
                title={titleRelatedMixers}
                mixers={relatedMixers}
                open={openRelatedMixersModal}
                onClose={() => setOpenRelatedMixersModal(false)}
              />
            </>
          )
        }
      </Container>
      <QueryFilterPumpTravelReport
        open={openFilterFields}
        onClose={() => setOpenFilterFields(false)}
        initialDateRange={defaultDateRange}
        onFilter={handleApplyFilter}
      />
    </>
  );
};

export default TravelPumpReport;
