import React, { ReactElement } from "react";

// region Libraries
import { withStyles } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { TimelineItemBase } from "react-calendar-timeline";
import { Tooltip } from "@mui/material";
import moment from "moment/moment";
// endregion Libraries
// region Styles
import { InfoLabel } from "./styles";
// endregion Styles

type TimelinePumpStatusTooltipProps = {
  children: ReactElement,
  isOpen: boolean,
  timelineItem?: TimelineItemBase<number>,
  itemId: string;
  onClick: () => void,
  stateProps: {
    stateBackground: string,
    stateIcon: string
  }
};
const TimelinePumpStatusTooltip: React.FC<TimelinePumpStatusTooltipProps> = ({ children, onClick, timelineItem, isOpen, itemId, stateProps }) => {
  if (!timelineItem) return children;

  const StyledTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: `${stateProps.stateBackground} !important`,
      color: "#000",
      padding: "0 !important",
      borderRadius: "0.5rem !important"
    },
    arrow: {
      color: `${stateProps.stateBackground} !important`
    }
  }))(Tooltip);

  const itemState = itemId.toString().split("-")[0];
  const formattedDuration = moment.utc(timelineItem.end_time - timelineItem.start_time).format("H[h] mm[m]");
  const { stateBackground, stateIcon } = stateProps;

  return (
    <StyledTooltip
      arrow
      placement="top"
      onClick={() => onClick()}
      open={isOpen}
      PopperProps={{
        disablePortal: true
      }}
      title={
        <InfoLabel stateColor={stateBackground} onClick={() => onClick()}>
          <div className="container">
            <ReactSVG src={stateIcon} />
            <div className="timelineText">
              <span>{ itemState }</span>
              <p>{ new Date(timelineItem.start_time).toLocaleTimeString("pt-br", {
                hour: "2-digit",
                minute: "2-digit"
              }) } ► { moment(timelineItem.end_time).format("HH:mm") }
              </p>
              <p>{ formattedDuration }</p>
            </div>
          </div>
        </InfoLabel>
      }>
      { children }
    </StyledTooltip>

  );
};

export default TimelinePumpStatusTooltip;
