import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import styled from "styled-components";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${(prop) => (prop.platform === "mobile" ? "5rem" : "0")};
  padding-top: 40px !important;
  height: 100%;

  .dataTables_scroll .dataTables_scrollBody {
    max-height: calc(100vh - 350px) !important;
  }
`;

export const ContainerModalDataTable = styled.div`
  z-index: 2001;

  .MuiDialog-paper {
    width: 1200px;

    .mContent {

      .MuiTypography-root {
        width: 100%;
      }
      .MuiFormControl-root {
        width: 100%;

        .MuiFormHelperText-root {
          text-align: right;
          margin: 0;
        }
      }
    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;
