import styled from "styled-components";

export const InfoLabel = styled.div<{ stateColor: string }>`
  .container {
    padding: 6px 18px 6px 8px;
    width: 120px;
    border-radius: 5px;
    background-color: ${(props) => props.stateColor};
    z-index: 90;
    color: black;
  }
`;
