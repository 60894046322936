export enum PumpTimelineMessages {
  state = "pumpTimeline.state",
  destination = "pumpTimeline.destination",
  driver = "pumpTimeline.driver",
  tooltipSpeedThreshold = "pumpTimeline.tooltipSpeedThreshold",
  tooltipGenerateAt = "pumpTimeline.tooltipGenerateAt",
}

export type TranslationPumpTimelineMessages = {
  [key in PumpTimelineMessages]: string;
}
