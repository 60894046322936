import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}
export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-content: flex-start;
  padding-bottom: 20px !important;

  &.page {
    padding-top: 1rem;
  }

  .minWidthNoteColumn {
    @media (min-width: 512px) {
      min-width: 380px;
    }
  }

  @media (max-width: 800px) { // Mobile
    padding-bottom: 0;
  }
`;
