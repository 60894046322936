export enum MenuMessages {
  production = "menu.production",
  pumps = "menu.pumps",
  supportVehicles = "menu.supportVehicles",
  trucks = "menu.trucks",
  dashboards = "menu.dashboards",
  management = "menu.management",
  users = "menu.users",
  modules = "menu.modules",
  drivers = "menu.drivers",
  vehicles = "menu.vehicles",
  locations = "menu.locations",
  hardwares = "menu.hardwares",
  SIMCards = "menu.SIMCards",
  reports = "menu.reports",
  scheduledReports = "menu.scheduledReports",
  travelHistory = "menu.travelHistory",
  alertHistory = "menu.alertHistory",
  averageSpeed = "menu.averageSpeed",
  coordinateAnalysis = "menu.coordinateAnalysis",
  vehicleHistory = "menu.vehicleHistory",
  vehicleStatesHistory = "menu.vehicleStatesHistory",
  others = "menu.others",
  contactUs = "menu.contactUs",
  settings = "menu.settings",
  installApp = "menu.installApp",
  exit = "menu.exit",
  generalLocation = "menu.generalLocation",
  vehiclesUnderMaintenance = "menu.vehiclesUnderMaintenance",
  alerts = "menu.alerts",
  speedingAlerts = "menu.speedingAlerts",
  concreteExpiration = "menu.concreteExpiration",
  improperDischarges = "menu.improperDischarges",
  vehiclesInTrustedLocations = "menu.vehiclesInTrustedLocations",
  blockedVehicles = "menu.blockedVehicles",
  regionals = "menu.regionals",
  groups = "menu.groups",
  help = "menu.help",
  pumpProgramming = "menu.pumpProgramming",
  relatedMixersDetails = "menu.relatedMixersDetails",
  zones = "menu.zones",
  driversRoles = "menu.driversRoles"
}

export type TranslationMenuMessages = {
  [key in MenuMessages]: string;
}
