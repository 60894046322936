export enum PumpTimelineTypes {
  SUMMARY = "Summary",
  DATA = "Data",
  ALERT = "Alert",
  GAP = "Gap",
  SUBGROUP = "SubGroup",
}

export enum PumpTimelineGroupsHeights {
  SUMMARY = 30,
  DATA = 80,
  ALERT = 10,
  GAP = 14,
  SUBGROUP = 25,
}

export enum PumpStatusTypes {
  OCIOSA = "Ociosa",
  BOMBEANDO = "Bombeando"
}

export enum PumpStatusTypesColors {
  OCIOSA = "#F70700",
  BOMBEANDO = "#8B92BB"
}
