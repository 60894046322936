import { MaintenancesModuleMessages, TranslationMaintenancesModuleMessages } from "../interfaces";

export const messages: TranslationMaintenancesModuleMessages = {
  [MaintenancesModuleMessages.maintenanceHistory]: "Historial de mantenimientos",
  [MaintenancesModuleMessages.hardwareType]: "Tipo de hardware",
  [MaintenancesModuleMessages.problemType]: "Tipo de problema",
  [MaintenancesModuleMessages.note]: "Nota",
  [MaintenancesModuleMessages.vehicleExternalId]: "ID externo de vehículo",
  [MaintenancesModuleMessages.vehicleCode]: "Código de vehículo",
  [MaintenancesModuleMessages.vehicleType]: "Tipo de vehículo",
  [MaintenancesModuleMessages.resolutionDate]: "Fecha de resolución",
  [MaintenancesModuleMessages.diagnostic]: "Diagnóstico",
  [MaintenancesModuleMessages.zendeskTicket]: "Chamado de Serviço",
  [MaintenancesModuleMessages.occurrenceDate]: "Fecha de ocurrencia",
  [MaintenancesModuleMessages.hardwareType]: "Tipo de hardware",

  [MaintenancesModuleMessages.getMaintenanceSuccess]: "Mantenimientos encontrados con éxito",
  [MaintenancesModuleMessages.getMaintenanceError]: "Error al encontrar mantenimientos",
  [MaintenancesModuleMessages.noMaintenanceFound]: "No se encontraron mantenimientos"
};
