import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Regional } from "@shared/entities/reg_regionals.entity";
import { Group } from "@shared/entities/reg_groups.entity";
import { Plant } from "@shared/entities/reg_plants.entity";
import { ParamNameTypes } from "@shared/constants/param-name-types.enum";
import { TravelFilterOptionsInterface } from "@shared/interfaces/travel-filter-options.interface";
import {  PumpWithMixers } from "@shared/interfaces/pump-travel-report.interface";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  FilterMessages,
  GlobalMessages,
  MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IDateRangeValues,
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";
import { InternalCodeErrors } from "@shared/constants/internal-code-errors.enum";
// endregion Imports - Organisms

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  initialDateRange?: IDateRangeValues;
  onFilter: (
    numberOfFilteredOptions: number,
    pumpTravelReports: PumpWithMixers[]
  ) => void;
}
// endregion Interfaces

const QueryFilterPumpTravelReport: React.FC<IProps> = ({
  open,
  onClose,
  initialDateRange,
  onFilter
}) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region States
  const [optionsVehiclePumpFilter, setOptionsVehiclePumpFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsConstructionFilter, setOptionsConstructionFilter] = useState<string[]>([] as Array<string>);
  const [optionsClientFilter, setOptionsClientFilter] = useState<string[]>([] as Array<string>);
  const [optionsDocumentFilter, setOptionsDocumentFilter] = useState<string[]>([] as Array<string>);
  const [optionsPlantFilter, setOptionsPlantFilter] = useState<Plant[]>([] as Array<Plant>);
  const [optionsRegionalFilter, setOptionsRegionalFilter] = useState<Regional[]>([] as Array<Regional>);
  const [optionsGroupFilter, setOptionsGroupFilter] = useState<Group[]>([] as Array<Group>);

  const [loadingVehiclePumpFilter, setLoadingVehiclePumpFilter] = useState(true);
  const [loadingPlantFilter, setLoadingPlantFilter] = useState(true);
  const [loadingRegionalFilter, setLoadingRegionalFilter] = useState(true);
  const [loadingGroupFilter, setLoadingGroupFilter] = useState(true);
  const [loadingDocumentFilter, setLoadingDocumentFilter] = useState(true);
  const [loadingConstructionFilter, setLoadingConstructionFilter] = useState(false);
  const [loadingClientFilter, setLoadingClientFilter] = useState(false);
  const [loadingPumpData, setLoadingPumpData] = useState(false);
  // endregion States
  // region Constants

  // Param names
  const paramNameStartDate = ParamNameTypes.START_DATE;
  const paramNameEndDate = ParamNameTypes.FINISH_DATE;
  const paramNameVehicle = ParamNameTypes.ID_VEHICLES;
  const paramNamePlant = ParamNameTypes.ID_PLANTS;
  const paramNameGroup = ParamNameTypes.ID_GROUPS;
  const paramNameRegional = ParamNameTypes.ID_REGIONALS;
  const paramNameConstruction = ParamNameTypes.CONSTRUCTION_NAMES;
  const paramNameClient = ParamNameTypes.CLIENTS;
  const paramNameNumDoc = ParamNameTypes.NUM_DOCS;

  const regionalFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingRegionalFilter,
    labelName: t(MenuMessages.regionals),
    paramName: paramNameRegional,
    values: optionsRegionalFilter.map(
      (regional) => ({
        value: regional.id_regional!,
        label: `${regional.code} - ${regional.name}`
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingRegionalFilter, optionsRegionalFilter, t]);

  const groupFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingGroupFilter,
    labelName: t(MenuMessages.groups),
    paramName: paramNameGroup,
    values: optionsGroupFilter.map(
      (group) => ({
        value: group.id_group!,
        label: `${group.code} - ${group.name}`
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingGroupFilter, optionsGroupFilter, t]);

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehiclePumpFilter,
    labelName: t(GlobalMessages.vehicle),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehiclePumpFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingVehiclePumpFilter, optionsVehiclePumpFilter, t]);

  const plantFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingPlantFilter,
    labelName: t(FilterMessages.optionPlants),
    paramName: paramNamePlant,
    values: optionsPlantFilter.map(
      (plant) => ({
        value: plant!.location!.id_location!,
        label: plant.name!
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingPlantFilter, optionsPlantFilter, t]);

  const constructionFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingConstructionFilter,
    labelName: t(FilterMessages.optionConstructions),
    paramName: paramNameConstruction,
    values: optionsConstructionFilter.map((construction) => ({ value: construction, label: construction })),
    refreshOptions: {
      refresh: (currentValues) => {
        const startDate = currentValues.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
        const endDate = currentValues.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;

        if (!startDate || !endDate) return;

        updateConstructionClientsOptionsFilters(startDate, endDate).then();
      },
      dependentParamNames: [paramNameStartDate, paramNameEndDate]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingConstructionFilter, optionsConstructionFilter, t]);

  const documentFilterOptions: IMultipleSelectionOptions = useMemo (() => ({
    isLoadingValues: loadingDocumentFilter,
    labelName: t(FilterMessages.optionNumDoc),
    paramName: paramNameNumDoc,
    values: optionsDocumentFilter.map((doc) => ({ value: doc , label: doc })),
    refreshOptions: {
      refresh: (currentValues) => {
        const startDate = currentValues.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
        const endDate = currentValues.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;

        if (!startDate || !endDate) return;

        updateConstructionClientsOptionsFilters(startDate, endDate).then();
      },
      dependentParamNames: [paramNameStartDate, paramNameEndDate]
    }
  }), [loadingDocumentFilter, optionsDocumentFilter, t]);

  const clientFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingClientFilter,
    labelName: t(FilterMessages.optionClients),
    paramName: paramNameClient,
    values: optionsClientFilter.map((client) => ({ value: client, label: client }))
  }), [loadingClientFilter, optionsClientFilter, paramNameClient, t]);

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: initialDateRange?.startDate ?? utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: initialDateRange?.endDate ?? utils.convertDateToISOWithTimezone(defaultEndDate)
    },
    maxRange: "7D"
  };

  // endregion Constants
  // region Refs
  const appliedFilter = useRef<IParamQueryValues[]>([]);
  // endregion Refs
  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get(
        "vehicles/read",
        { params: { linkedHardware: true, onlyLabel: true, idVehicleType: VehicleTypesID.CAMINHAO_BOMBA } }
      );

      if (data.status === "success") setOptionsVehiclePumpFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehiclePumpFilter(false);
    }
  };

  /**
   * Load regional options from the API.
   */
  const loadRegionalOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setOptionsRegionalFilter(data.result as Regional[]);
      else setOptionsRegionalFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingRegionalFilter(false);
    }
  };

  /**
   * Load plant options from the API.
   */
  const loadPlantOptions = async () => {
    try {

      // Get all plants
      const { data } = await api.get("plants/read", { params: { linkedLocation: true } });

      if (data.status === "success") setOptionsPlantFilter(data.result);
      else setOptionsPlantFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingPlantFilter(false);
    }
  };

  /**
   * Load group options from the API.
   */
  const loadGroupOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("groups/read");

      if (data.status === "success") setOptionsGroupFilter(data.result as Group[]);
      else setOptionsGroupFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingGroupFilter(false);
    }
  };

  /**
   * Load construction and client filter options according to the selected date range.
   */
  const updateConstructionClientsOptionsFilters = async (
    startDate = initialDateRange?.startDate ?? utils.convertDateToISOWithTimezone(defaultStartDate),
    finishDate = initialDateRange?.endDate ?? utils.convertDateToISOWithTimezone(defaultEndDate)
  ) => {

    setLoadingConstructionFilter(true);
    setLoadingClientFilter(true);
    setLoadingDocumentFilter(true);

    try {

      const { data } = await api.get(
        "travels/get-travel-filter-options", {
          params: {
            startDate,
            finishDate,
            finished: true,
            vehicleTypeId: VehicleTypesID.CAMINHAO_BOMBA
          }
        }
      );

      if (data.status === "success") {
        const travelFilterOptions = data.result as TravelFilterOptionsInterface;

        setOptionsClientFilter(travelFilterOptions.clients);
        setOptionsConstructionFilter(travelFilterOptions.constructionNames);
        setOptionsDocumentFilter(travelFilterOptions.documentsNumbers ?? []);

        return;
      }
      setOptionsClientFilter([]);
      setOptionsConstructionFilter([]);
      setOptionsDocumentFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingConstructionFilter(false);
      setLoadingClientFilter(false);
      setLoadingDocumentFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[]) => {

    setLoadingPumpData(true);

    appliedFilter.current = paramsData;

    try {

      const mappedParams = paramsData.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("travels/get-pump-report", mappedParams);

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = validParamsData.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status === "alert" && data.errorCode === InternalCodeErrors.VERY_LARGE_FILTER) {
        const additionalFilterOptions : IParamQueryValues[] = [
          { paramName: ParamNameTypes.UTC_OFFSET, paramValue: (utils.getUtcOffset()).toString() }
        ];

        return { status: "scheduledReport", additionalFilterOptions } as IOnFilterReturn;
      }

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? []
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoadingPumpData(false);
    }
  };
  // endregion Functions
  // region Effects

  // Load data from the API when the report type changes
  useEffect(() => {

    if (!_.isEmpty(appliedFilter.current)) handleFilter(appliedFilter.current).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load options from the API
  useEffect(() => {

    Promise.all([
      updateConstructionClientsOptionsFilters(),
      loadVehicleOptions(),
      loadRegionalOptions(),
      loadGroupOptions(),
      loadPlantOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // endregion Effects

  return (
    <>
      <Loading loading={loadingPumpData} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.PUMP_TRAVEL_REPORT}
        onFilter={(paramsData) => handleFilter(paramsData)}
        multipleSelectionOptions={[
          clientFilterOptions,
          constructionFilterOptions,
          vehicleFilterOptions,
          documentFilterOptions,
          groupFilterOptions,
          regionalFilterOptions,
          plantFilterOptions
        ]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterPumpTravelReport;
