import {
  RelatedMixerTravelReport
} from "@shared/interfaces/pump-travel-report.interface";
import {
  GlobalMessages,
  MenuMessages,
  TravelHistoryMessages, VehiclesStatesMessages
} from "@shared/languages/interfaces";
import NewDataTable, { DataTableColumns } from "@molecules/NewDataTable";
import React from "react";
import { t } from "i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { ContainerModalDataTable } from "./styles";
import useTranslation from "src/translations/useTranslation";
import usePersistedState from "@hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { useSelector } from "react-redux";

type MixersRelatedModalProps = { title: string,  mixers: RelatedMixerTravelReport[], open: boolean; onClose }

const MixersRelatedDetailsModal: React.FC<MixersRelatedModalProps> = ({ title, mixers, open, onClose }) => {

  // region Hooks
  const { t } = useTranslation();
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { screen } = useSelector((screen) => screen);
  // endregion Hooks

  const dataTableColumns: DataTableColumns[] = [
    {
      title: t(GlobalMessages.vehicle),
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelVehicleCode ?? "",
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.document),
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelNumDoc ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.onTheWay)} - ${t(VehiclesStatesMessages.columnStartDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelOnTheWayStartDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.onTheWay)} - ${t(VehiclesStatesMessages.columnEndDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelOnTheWayEndDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.atTheConstructionSite)} - ${t(VehiclesStatesMessages.columnStartDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelOnConstructionStartDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.atTheConstructionSite)} - ${t(VehiclesStatesMessages.columnEndDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelOnConstructionEndDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.unloading)} - ${t(VehiclesStatesMessages.columnStartDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelDischargingStartDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.unloading)} - ${t(VehiclesStatesMessages.columnEndDate)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelDischargingEndDate ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.unloading)} - ${t(VehiclesStatesMessages.columnDuration)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelDischargingDuration ?? "",
      filterable: false
    },
    {
      title: `${t(VehiclesStatesMessages.returning)}`,
      className: "print csv",
      data: (record: RelatedMixerTravelReport) => record.mixerTravelReturningStartDate ?? "",
      filterable: false
    }
  ];

  return (
    <ContainerModalDataTable id="dataTable">

      <Dialog
        className="dialog"
        open={open}
        onClose={onClose}
        fullWidth
        scroll="paper"
        maxWidth="xl"
        container={document.getElementById("modalPumpDetails")}
      >
        <DialogTitle className="mHeader">
          <div className="content">
            <div className="title">
              {`${t(MenuMessages.relatedMixersDetails)} - ${title}`}
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers className="mContent">
          <NewDataTable
            title={t(MenuMessages.relatedMixersDetails)}
            data={mixers}
            columns={dataTableColumns}
            showPaging={false}
          />
        </DialogContent>
      </Dialog>
    </ContainerModalDataTable>
  );
};

export default MixersRelatedDetailsModal;
