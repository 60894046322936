export enum FilterApplicationTypesID {
  TRAVEL_HISTORY = "f9f3e0e0-3d9d-4d4b-8b5c-6b8f2a6a3f3e",
  ALERT_HISTORY = "a1e2d3b3-9e9d-4e9c-a89e-87c44d179228",
  VEHICLE_HISTORY = "4b76ca40-8c29-4617-837d-a4924d265570",
  COORDINATE_TRAVEL_HISTORY = "05b8e88d-18e4-488c-a9d9-e64cdb8aa144",
  PUMP_PROGRAMMING = "9abfba72-3241-4565-bf7e-1e3396fe1f63",
  PUMP_PROGRAMMING_HISTORY = "deb7163f-b3c9-41ed-a7d4-09b05f00ee20",
  AVERAGE_SPEED_HISTORY = "b39f0a4e-7f71-4b54-9846-b26614faf749",
  VEHICLE_STATE_HISTORY = "fa664a28-76b5-4be7-9fb4-154f88b5c6ae",
  VEHICLE_ANALYTIC_REPORT = "d72c3aac-db53-4aec-98e6-88971627850b",
  INTEGRATION_REPORT = "05ae7ecf-a5a7-4024-b87e-9a3ef463291a",
  MAINTENANCES = "4fc77352-82cd-4815-b324-6c3bf20ca147",
  PUMP_TRAVEL_REPORT = "bb6a6dfe-90b1-4dc5-9294-9f795551f928"
}

export function getFilterApplicationTypeName(id: string | undefined): string | undefined {
  if (!id) return undefined;

  for (const [key, value] of Object.entries(FilterApplicationTypesID)) {
    if (value === id) {
      return key;
    }
  }

  return undefined;
}
