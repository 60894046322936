/* eslint-disable no-extend-native */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

// region Libraries
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress,
  Grid, List, ListItem, ListItemText, Menu, MenuItem } from "@material-ui/core";
import closedLock from "@assets/icons/closedLock.svg";
import { FaChevronDown } from "react-icons/fa";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { VehiclesStatesTypesMessages } from "@shared/languages/interfaces/vehiclesStatesMessages";
// endregion Languages
// region Store
import { SectionsMenuOptions, VehiclesStatesObj } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Store
// region Constants
import {
  VehicleStatesTypes,
  VehicleStatesTypesColors,
  VehicleStatesTypesID
} from "@shared/constants/vehicle-states-types.enum";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
// endregion Constants
// region Interfaces
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Interfaces
// region Components
import FloatingMenu from "@components/FloatingMenu";
import {
  IconIgnitionOn,
  ExpandAll
} from "@libraries/icons";
import TimeLineItem from "./TimeLineVehiclesItem";
// endregion Components
// region Props
import { SectionProps } from "../SectionProps";
// endregion Props
// region Styles
import { Container } from "./styles";
import { FilterMessages } from "@shared/languages/interfaces";
// endregion Styles
// region Imports - Icons
// endregion Imports - Icons

const TimeLine: React.FC = () => {

  // region Hooks
  const { t } = useTranslation();
  const filtersDashboard = useSelector(({ filtersDashboard: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const screen = useSelector(({ screen: state }) => state);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndexFilterMenu, setSelectedIndexFilterMenu] = React.useState<SectionsMenuOptions[]>([]);
  const openFilterMenu = Boolean(anchorEl);
  const [sectionActual, setSectionActual] = React.useState<number>(1);
  // endregion Hooks
  // region States
  const [vehicles, setVehicles] = useState<VehiclesStatesObj>(vehiclesStates.all);
  // endregion States

  // Verify if filter is active to show filtered vehicles or all vehicles
  useEffect(() => {
    if (filtersDashboard.active) setVehicles(vehiclesStates.filtered as VehiclesStatesObj);
    else setVehicles(vehiclesStates.all);
  }, [filtersDashboard, vehiclesStates]);

  const handleClickFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSectionActual(Number(event.currentTarget.id));
    setAnchorEl(event.currentTarget);
  };

  const handleClickFilterMenuItem = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndexFilterMenu(selectedIndexFilterMenu.map((_selectedIndexFilterMenu) => {

      const selectedIndexFilterMenu = _selectedIndexFilterMenu;

      if (anchorEl && _selectedIndexFilterMenu.idSection === Number(anchorEl.id)) {

        selectedIndexFilterMenu.selectedIndex = index;
      }

      return selectedIndexFilterMenu;
    }));
    setAnchorEl(null);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const filterMenuOptions = [
    t(FilterMessages.recentActivity),
    t(FilterMessages.ignitionOn),
    t(FilterMessages.ignitionOff)
  ];

  // region Functions

  /** Counts the number of vehicles taking into account whether they will be all, just the production ones or
   *  blocked ones
   * @param allVehicles
   * @param vehicles
   * @param blockedVehicles
   */
  const countVehicles = useCallback((allVehicles = true, vehiclesParam: Vehicle[], blockedVehicles?, ignitionOn?: boolean): number => {

    if (!vehiclesParam) return 0;
    if (allVehicles) return vehicles.list[VehicleStatesTypes.NA_USINA].length;
    if (blockedVehicles) {
      return vehiclesParam.reduce((total: number, vehicle: Vehicle) => {
        if (vehicle.blocked) {
          // eslint-disable-next-line no-param-reassign
          total += 1;
        }

        return total;
      }, 0);
    }

    if (ignitionOn) {
      return vehiclesParam.filter((vehicle) => vehicle.engine === true).length;
    }

    return vehiclesParam.reduce((total, vehicle) => (
      (vehicle.type.id_vehicle_type === VehicleTypesID.BETONEIRA || vehicle.type.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA)
        ? total + 1
        : total), 0);

  }, [vehicles.list]);
  // endregion Functions

  const sections: SectionProps[] = [
    {
      id: 1,
      description: t(VehiclesStatesTypesMessages[VehicleStatesTypesID.NA_USINA]),
      objectKey: VehicleStatesTypes.NA_USINA,
      background: VehicleStatesTypesColors.NA_USINA,
      xs: 12,
      sm: 6
    },
    {
      id: 2,
      description: t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO]),
      objectKey: VehicleStatesTypes.A_CAMINHO,
      background: VehicleStatesTypesColors.A_CAMINHO,
      xs: 12,
      sm: 6
    },
    {
      id: 3,
      description: t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_OBRA]),
      objectKey: VehicleStatesTypes.EM_OBRA,
      background: VehicleStatesTypesColors.EM_OBRA,
      xs: 12,
      sm: 6
    },
    {
      id: 4,
      description: t(VehiclesStatesTypesMessages[VehicleStatesTypesID.DESCARREGANDO]),
      objectKey: VehicleStatesTypes.DESCARREGANDO,
      background: VehicleStatesTypesColors.DESCARREGANDO,
      xs: 12,
      sm: 6
    },
    {
      id: 5,
      description: t(VehiclesStatesTypesMessages[VehicleStatesTypesID.RETORNANDO]),
      objectKey: VehicleStatesTypes.RETORNANDO,
      background: VehicleStatesTypesColors.RETORNANDO,
      xs: 12,
      sm: 12
    }
  ];

  useEffect(() => {
    sections.forEach((section) => {
      setSelectedIndexFilterMenu((prevSelectedIndexFilterMenu) => [...prevSelectedIndexFilterMenu, { idSection: section.id, selectedIndex: 0 }]);
    });
  }, []);

  const renderTimelineDesktop = useCallback(() => (
    <Container>
      <Grid container spacing={2} className="list">
        {sections.map((section) => (
          <Grid key={section.id} item xs={section.xs} sm={section.sm} md lg xl component="div" className="section">
            <div className="indicator" style={{ background: section.background }}>

              <div className="topIndicator">
                <div className="description">{section.description}</div>
                {countVehicles(false, vehicles.list[section.objectKey], true) > 0 && (
                  <div className="blockedCount">
                    <ReactSVG src={closedLock} />
                    { countVehicles(false, vehicles.list[section.objectKey], true) }
                    <div className="vertical_line" />
                  </div>
                )}
                <div className="count">
                  <span>
                    <ReactSVG src={IconIgnitionOn} wrapper="svg" />
                    {countVehicles(false, vehicles.list[section.objectKey], false, true)}
                  </span>
                  {countVehicles(false, vehicles.list[section.objectKey])}
                </div>
              </div>

              <div className="filterMenu">
                <List
                  component="nav"
                >
                  <Button
                    id={section.id.toString()}
                    onClick={handleClickFilterMenu}
                    endIcon={<ReactSVG src={ExpandAll} wrapper="svg" />}
                  >
                    <ListItemText
                      secondary={
                        filterMenuOptions[
                          selectedIndexFilterMenu !== undefined
                            ? (selectedIndexFilterMenu.find(
                              (_selectedIndexFilterMenu) => _selectedIndexFilterMenu.idSection === section.id
                            )?.selectedIndex ?? 0)
                            : 0]
                      }
                    />
                  </Button>
                </List>

              </div>

            </div>
            <ListItem className="items">
              { !vehicles.loading
                ? (
                  <TimeLineItem
                    vehicles={
                      vehicles.list[section.objectKey]
                        ? vehicles.list[section.objectKey].filter((vehicle) => {

                          if (selectedIndexFilterMenu !== undefined && (selectedIndexFilterMenu.find(
                            (_section) => _section.idSection === section.id
                          )?.selectedIndex ?? 0) === 0) return vehicle;

                          if (selectedIndexFilterMenu !== undefined && (selectedIndexFilterMenu.find(
                            (_section) => _section.idSection === section.id
                          )?.selectedIndex ?? 0) === 1) return vehicle.engine === true;

                          if (selectedIndexFilterMenu !== undefined && (selectedIndexFilterMenu.find(
                            (_section) => _section.idSection === section.id
                          )?.selectedIndex ?? 0) === 2) return vehicle.engine === false;

                          return false;
                        })
                        : []
                    }
                    showAllVehicles={false}
                  />
                )
                : <CircularProgress /> }
            </ListItem>
          </Grid>
        ))}
      </Grid>
      <FloatingMenu />
      <Menu
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={handleCloseFilterMenu}
        MenuListProps={{
          role: "listbox"
        }}
      >
        {filterMenuOptions.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === (selectedIndexFilterMenu !== undefined
              ? (selectedIndexFilterMenu.find((_selectedIndexFilterMenu) => _selectedIndexFilterMenu.idSection === sectionActual)?.selectedIndex ?? 0)
              : 0)}
            onClick={(event) => handleClickFilterMenuItem(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  ), [vehicles, countVehicles, sections]);

  const renderTimelineMobile = useCallback(() => (
    <Container className="mobile">
      <Grid container spacing={2} className="list">
        {sections.map((section) => (
          <Grid key={section.id} item xs={section.xs} sm={section.sm} md lg xl component="div" className="section">
            <Accordion className="accordionContainer" defaultExpanded>
              <AccordionSummary
                className="accordionSummary"
                style={{ background: section.background }}
                expandIcon={<FaChevronDown fontSize={16} opacity={0.5} color="white" />}
              >
                <div className="description">{section.description}</div>
                <div className="count">{countVehicles(false, vehicles.list[section.objectKey])}</div>
              </AccordionSummary>
              <AccordionDetails className="accordionDetails" style={{ width: "100%", padding: "0" }}>
                <ListItem className="items">
                  {!vehicles.loading
                    ? (
                      <TimeLineItem
                        vehicles={vehicles.list[section.objectKey] ? vehicles.list[section.objectKey] : []}
                        showAllVehicles={false}
                      />
                    )
                    : <CircularProgress />}
                </ListItem>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      <FloatingMenu />
    </Container>
  ), [vehicles, countVehicles, sections]);

  return screen.platform === ScreenPlatform.DESKTOP ? renderTimelineDesktop() : renderTimelineMobile();

};

export default TimeLine;
