export enum MaintenancesModuleMessages {
  /* region Frontend messages */
  maintenanceHistory = "maintenancesModule.maintenanceHistory",
  hardwareType = "maintenancesModule.hardwareType",
  vehicleExternalId = "maintenancesModule.vehicleExternalId",
  problemType = "maintenancesModule.problemType",
  note = "maintenancesModule.note",
  vehicleCode = "maintenancesModule.vehicleCode",
  vehicleType = "maintenancesModule.vehicleType",
  resolutionDate = "maintenancesModule.resolutionDate",
  diagnostic = "maintenancesModule.diagnosis",
  zendeskTicket = "maintenancesModule.zendeskTicket",
  occurrenceDate = "maintenancesModule.occurrenceDate",
  /* endregion Frontend messages */

  /* region Backend messages */
  getMaintenanceSuccess = "maintenancesModule.getMaintenanceSuccess",
  getMaintenanceError = "maintenancesModule.getMaintenanceError",
  noMaintenanceFound = "maintenancesModule.noMaintenanceFound"
  /* endregion Backend messages */
}

export type TranslationMaintenancesModuleMessages = {
  [key in MaintenancesModuleMessages]: string;
}
