/* eslint-disable camelcase, max-classes-per-file */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { Travel } from "./reg_travels.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { Hardware } from "./reg_hardwares.entity";
import { DriversRoles } from "./reg_drivers_roles.entity";
import { Group } from "./reg_groups.entity";
import { Regional } from "./reg_regionals.entity";

@Entity("reg_drivers")
export class Driver {

  @PrimaryGeneratedColumn("uuid")
    id_driver?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @Column({ type: "varchar", nullable: false })
    name?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
    cpf?: string;

  @Column({ type: "varchar", nullable: true })
    license_number?: string;

  @Column({ type: "timestamp", nullable: true })
    license_expired_date?: Date;

  @Column({ type: "varchar", nullable: true, unique: true })
    re?: string;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @Column({ type: "varchar", nullable: true })
    third_party_code?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Travel, (travels) => travels.driver)
    travels?: Travel[];

  @OneToMany(() => Vehicle, (vehicles) => vehicles.driver)
    vehicles?: Vehicle[];

  @OneToOne(() => Hardware, (hardwares) => hardwares.driver, { nullable: true })
  @JoinColumn({ name: "id_hardware_fk" })
    hardware?: Hardware;

  GetLabelName?(): string {
    return `${this.name} - ${this.cpf}`;
  }

  @Column({ type: "varchar", nullable: true })
    id_driver_role_fk?: string;

  @OneToOne(() => DriversRoles, (driversRoles) => driversRoles.id_driver_role, { nullable: true })
  @JoinColumn({ name: "id_driver_role_fk" })
    drivers_roles?: DriversRoles;

  @Column({ type: "varchar", nullable: true })
    ownership?: string;

  @Column({ type: "varchar", nullable: true })
    id_group_fk?: string;

  @Column({ type: "varchar", nullable: true })
    id_regional_fk?: string;

  @Column({ type: "varchar", nullable: true })
    responsible_emails?: string;

  @Column({ type: "varchar", nullable: true })
    id_plant_fk?: string;

  @Column({ type: "varchar", nullable: true })
    driver_license_restrictions?: string;

  @Column({ type: "timestamp", nullable: true })
    cnh_emission_date?: Date;
}

export class DriverErrors {

  static readonly DUPLICATED_CPF = "3.2.1 (TCON333231)";

  static readonly DRIVER_IN_USE = "3.2.2 (TCON333232)";

}
