/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { Vehicle } from "./reg_vehicles.entity";

@Entity("reg_maintenances")
export class Maintenance {

  @PrimaryGeneratedColumn("uuid")
    id_maintenance?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @Column({ type: "varchar", nullable: true })
    source_vehicle_code?: string;

  @Column({ type: "varchar", nullable: true })
    service_request?: string;

  @Column({ type: "varchar", nullable: true })
    hardware_type?: string;

  @Column({ type: "varchar", nullable: true })
    diagnostic?: string;

  @Column({ type: "varchar", nullable: true })
    note?: string;

  @Column({ type: "timestamp", default: "(now() at time zone 'utc')", nullable: true })
    occurrence_date?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.maintenances, { nullable: true })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

}
