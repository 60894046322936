import { MaintenancesModuleMessages, TranslationMaintenancesModuleMessages } from "../interfaces";

export const messages: TranslationMaintenancesModuleMessages = {
  [MaintenancesModuleMessages.maintenanceHistory]: "Maintenance History",
  [MaintenancesModuleMessages.hardwareType]: "Hardware Type",
  [MaintenancesModuleMessages.problemType]: "Problem Type",
  [MaintenancesModuleMessages.note]: "Note",
  [MaintenancesModuleMessages.vehicleExternalId]: "Vehicle External Id",
  [MaintenancesModuleMessages.vehicleCode]: "Vehicle Code",
  [MaintenancesModuleMessages.resolutionDate]: "Resolution Date",
  [MaintenancesModuleMessages.vehicleType]: "Vehicle Type",
  [MaintenancesModuleMessages.diagnostic]: "Diagnosis",
  [MaintenancesModuleMessages.zendeskTicket]: "Service Ticket",
  [MaintenancesModuleMessages.occurrenceDate]: "Occurrence Date",


  [MaintenancesModuleMessages.getMaintenanceSuccess]: "Maintenances found successfully",
  [MaintenancesModuleMessages.getMaintenanceError]: "Error finding maintenance",
  [MaintenancesModuleMessages.noMaintenanceFound]: "No maintenance found"
};
