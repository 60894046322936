export enum MapMessages {
  updateVehicleCurrentPosition = "map.updateVehicleCurrentLocation",
  errorFetchingAddressData = "map.errorFetchingAddressData",
  locationSearchTitle = "map.locationSearchTitle",
  typeLocationLabel = "map.typeLocationLabel",
  allVehiclesSearchLabel = "map.allVehiclesSearch",
  addressSearchLabel = "map.addressSearch",
  route = "map.route",
  outwardTrip = "map.outwardTrip",
  returnTrip = "map.returnTrip",
  names = "map.names",
  licensePlates = "map.license_plates",
  alerts = "map.alerts",
  states = "map.states",
  events = "map.events",
  viewLocation = "map.viewLocation",
  zmrc = "map.zmrc",
  invalidPolygon = "map.invalidPolygon",
}

export type TranslationMapMessages = {
  [key in MapMessages]: string;
}
