import { MaintenancesModuleMessages, TranslationMaintenancesModuleMessages } from "../interfaces";

export const messages: TranslationMaintenancesModuleMessages = {
  [MaintenancesModuleMessages.maintenanceHistory]: "Histórico de Manutenções",
  [MaintenancesModuleMessages.hardwareType]: "Tipo de Hardware",
  [MaintenancesModuleMessages.problemType]: "Tipo de Problema",
  [MaintenancesModuleMessages.note]: "Nota",
  [MaintenancesModuleMessages.vehicleExternalId]: "Id Externo do Veículo",
  [MaintenancesModuleMessages.vehicleCode]: "Código do Veículo",
  [MaintenancesModuleMessages.vehicleType]: "Tipo do Veículo",
  [MaintenancesModuleMessages.resolutionDate]: "Data de Resolução",
  [MaintenancesModuleMessages.diagnostic]: "Diagnóstico",
  [MaintenancesModuleMessages.zendeskTicket]: "Chamado do Serviço",
  [MaintenancesModuleMessages.occurrenceDate]: "Data de Ocorrência",

  [MaintenancesModuleMessages.getMaintenanceSuccess]: "Manutenções encontradas com sucesso",
  [MaintenancesModuleMessages.getMaintenanceError]: "Erro ao encontrar manutenções",
  [MaintenancesModuleMessages.noMaintenanceFound]: "Nenhuma manutenção encontrada"
}
