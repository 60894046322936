/* eslint-disable import/prefer-default-export */
import {
  TranslationGlobalMessages,
  GlobalMessages
} from "../interfaces";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.dialogLandscape]: "Gire el dispositivo a la posición horizontal.",
  [GlobalMessages.loading]: "Cargando...",
  [GlobalMessages.noNameRegistered]: "Sin nombre registrado",
  [GlobalMessages.actionNotPermitted]: "Acción no permitida",
  [GlobalMessages.noAccess]: "Lo siento, no tienes acceso a la plataforma. Por favor, ponte en contacto con el soporte de Topcon.",
  [GlobalMessages.success]: "¡Éxito!",
  [GlobalMessages.alert]: "¡Alerta!",
  [GlobalMessages.error]: "Error",
  [GlobalMessages.connectionNotEstablished]: "Conexión no establecida",
  [GlobalMessages.pump]: "Bomba",
  [GlobalMessages.mixers]: "Mezcladoras",
  [GlobalMessages.close]: "Cerrar",
  [GlobalMessages.confirm]: "Confirmar",
  [GlobalMessages.confirmTravel]: "¿Quieres confirmar nueva velocidad?",
  [GlobalMessages.model]: "Modelo",
  [GlobalMessages.speed]: "Velocidad",
  [GlobalMessages.fullSpeed]: "velocidad maxima",
  [GlobalMessages.duration]: "Duración",
  [GlobalMessages.address]: "Dirección",
  [GlobalMessages.travelDetails]: "Detalles del viaje",
  [GlobalMessages.registerLocation]: "Registrar ubicación",
  [GlobalMessages.required]: "Obligatorio",
  [GlobalMessages.date]: "Fecha",
  [GlobalMessages.start]: "Inicio",
  [GlobalMessages.time]: "Hora",
  [GlobalMessages.end]: "Fin",
  [GlobalMessages.noData]: "Sin datos",
  [GlobalMessages.noRegisteredClients]: "Sin clientes registrados",
  [GlobalMessages.type]: "Tipo",
  [GlobalMessages.vehicle]: "Vehículo",
  [GlobalMessages.driver]: "Conductor",
  [GlobalMessages.observations]: "Observaciones",
  [GlobalMessages.register]: "Registrar",
  [GlobalMessages.yes]: "Sí",
  [GlobalMessages.no]: "No",
  [GlobalMessages.noClientRegistered]: "Sin cliente registrado",
  [GlobalMessages.quantity]: "Cantidad",
  [GlobalMessages.save]: "Guardar",
  [GlobalMessages.active]: "Activo",
  [GlobalMessages.inactive]: "Inactivo",
  [GlobalMessages.selectAll]: "Seleccionar todo",
  [GlobalMessages.search]: "Buscar",
  [GlobalMessages.apply]: "Aplicar",
  [GlobalMessages.internalErrorMessage]: "Error interno, contacte a nuestro soporte",
  [GlobalMessages.alreadyRegistered]: "ya registrado",
  [GlobalMessages.notRegistered]: "no registrado",
  [GlobalMessages.verifyFieldError]: "Falla al verificar campo",
  [GlobalMessages.wrongIDFormat]: "ID(s) no se encuentra(n) en el formato correcto",
  [GlobalMessages.other]: "Otro",
  [GlobalMessages.edit]: "Editar",
  [GlobalMessages.filter]: "Filtro",
  [GlobalMessages.exceeded]: "superado ",
  [GlobalMessages.exceededPT]: "superado ",
  [GlobalMessages.exceededBy]: "superado en ",
  [GlobalMessages.onTheWay]: "en el camino",
  [GlobalMessages.invalidFilter]: "Filtro inválido",
  [GlobalMessages.moment]: "Momento",
  [GlobalMessages.back]: "Volver",
  [GlobalMessages.updateRegister]: "Modificar registro",
  [GlobalMessages.errorLoadingGoogleMaps]: "Error al cargar la API de Google Maps"
};
