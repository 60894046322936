import { HardwareModuleMessages, TranslationHardwareModuleMessage } from "../interfaces";

export const messages: TranslationHardwareModuleMessage = {

  /* region Frontend messages */
  [HardwareModuleMessages.tabsHardware]: "Hardware",
  [HardwareModuleMessages.tabsHardwareInstallationHistory]: "Histórico de instalação",
  [HardwareModuleMessages.tabsCommands]: "Comandos",
  [HardwareModuleMessages.tabsLogs]: "Logs",
  [HardwareModuleMessages.tabsMaintenanceHistoru]: "Histórico de manutenção",

  /* region Commands tab */
  [HardwareModuleMessages.commandStatusPending]: "Pendente",
  [HardwareModuleMessages.commandStatusSent]: "Enviado",
  [HardwareModuleMessages.commandStatusProcessed]: "Processado",
  [HardwareModuleMessages.commandStatusCancelled]: "Cancelado",
  [HardwareModuleMessages.commandFilterCreation]: "Criação",
  [HardwareModuleMessages.commandFilterSend]: "Envio",
  [HardwareModuleMessages.commandFilterProcessing]: "Processamento",
  [HardwareModuleMessages.command]: "Comando",
  [HardwareModuleMessages.commandRegistrationDate]: "Criação",
  [HardwareModuleMessages.commandSendDate]: "Envio",
  [HardwareModuleMessages.commandProcessedDate]: "Processamento",
  [HardwareModuleMessages.commandAttempts]: "Tent.",
  [HardwareModuleMessages.commandResponse]: "Resp.",
  [HardwareModuleMessages.commandFormSelectVehicle]: "Selecione o veículo(s) para envio de comando",
  [HardwareModuleMessages.commandFormSelectCommand]: "Selecione ou digite o comando a ser enviado",
  [HardwareModuleMessages.commandFormRequiredCommand]: "Este campo é obrigatório",
  [HardwareModuleMessages.commandFilterNoTracker]: "Sem rastreador",
  [HardwareModuleMessages.commandFilterDateRange]: "Data final deve ser superior a inicial",
  [HardwareModuleMessages.commandFilterNoLinkedTracker]: "O veículo não tem um rastreador vinculado",
  [HardwareModuleMessages.commandTitle]: "Listar comandos",
  [HardwareModuleMessages.vehicle]: "Veículo",
  [HardwareModuleMessages.commandStatus]: "Status",
  [HardwareModuleMessages.commandFilterDateBy]: "Filtrar data por",
  [HardwareModuleMessages.startDate]: "Data inicial",
  [HardwareModuleMessages.finishDate]: "Data final",
  [HardwareModuleMessages.dataTableTitle]: "comandos de configuração",
  [HardwareModuleMessages.commandDeleteTitle]: "Confirmação de exclusão",
  [HardwareModuleMessages.commandDeleteText]: "Deseja realmente cancelar o comando?",
  [HardwareModuleMessages.devId]: "Dev ID",
  [HardwareModuleMessages.commandCreateTitle]: "Cadastro de comandos de configuração",
  [HardwareModuleMessages.commandCreateDefinedCommand]: "Comando pré-definido",
  [HardwareModuleMessages.commandCreateToSendCommand]: "Comando a ser enviado - Utilize a tag <DEV_ID> para"
  + " preenchimento automático",
  [HardwareModuleMessages.commandCreateNoVehicle]: "Selecione os veículos para envio dos comandos acima",
  [HardwareModuleMessages.commandCopyAlert]: "Comando copiado para a área de transferência",
  /* endregion Commands tab */
  /* region Hardware tab */
  [HardwareModuleMessages.hardwareFormTypeRequired]: "O tipo é obrigatório",
  [HardwareModuleMessages.hardwareFormBrandRequired]: "O fornecedor é obrigatório",
  [HardwareModuleMessages.hardwareFormModelRequired]: "O modelo é obrigatório",
  [HardwareModuleMessages.hardwareFormDevIdRequired]: "O DEV ID é obrigatório",
  [HardwareModuleMessages.hardwareFormPurchaseDateRequired]: "A data de compra é obrigatória",
  [HardwareModuleMessages.hardwareFormDevIdAlreadyExists]: "DEV ID já cadastrado",
  [HardwareModuleMessages.iButton]: "Ibutton",
  [HardwareModuleMessages.type]: "Tipo",
  [HardwareModuleMessages.brand]: "Fornecedor",
  [HardwareModuleMessages.model]: "Modelo",
  [HardwareModuleMessages.doBlock]: "Faz bloqueio",
  [HardwareModuleMessages.registrationDate]: "Data de cadastro",
  [HardwareModuleMessages.hardwareDataTableNoLinkedVehicle]: "Nenhum veículo vinculado",
  [HardwareModuleMessages.simCard]: "SIM Card",
  [HardwareModuleMessages.hardwareDataTableNoLinkedSimCard]: "Nenhum sim card vinculado",
  [HardwareModuleMessages.driver]: "Motorista",
  [HardwareModuleMessages.hardwareDataTableNoLinkedDriver]: "Nenhum motorista vinculado",
  [HardwareModuleMessages.hardwareDataTableTitle]: "hardwares",
  [HardwareModuleMessages.hardwareDeleteTitle]: "Confirmação de exclusão",
  [HardwareModuleMessages.hardwareDeleteText]: "Deseja realmente excluir o hardware",
  [HardwareModuleMessages.hardwareCreateTitle]: "Cadastro de hardwares",
  [HardwareModuleMessages.hardwareDetailsActive]: "Hardware ativo",
  [HardwareModuleMessages.hardwareDetailsType]: "Tipo de hardware",
  [HardwareModuleMessages.hardwareDetailsPurchaseDate]: "Data de compra",

  /* endregion Hardware tab */
  /* region Logs tab */
  [HardwareModuleMessages.logFormModelRequired]: "O modelo é obrigatório",
  [HardwareModuleMessages.logNoTracker]: "Sem rastreador",
  [HardwareModuleMessages.logTitle]: "Log de comunicações",
  [HardwareModuleMessages.logAutoRefresh]: "Auto Atualizar",
  [HardwareModuleMessages.logSearching]: "Buscando logs...",
  /* endregion Logs tab */
  /* region Hardware Installation History tab */
  [HardwareModuleMessages.hardwareDevId]: "DEV ID do hardware",
  [HardwareModuleMessages.hardwareType]: "Tipo do hardware",
  [HardwareModuleMessages.occurrenceDate]: "Data de ocorrência",
  [HardwareModuleMessages.importedData]: "Dado importado",
  /* endregion Hardware Installation History tab */

  /* endregion Frontend messages */
  /* region Backend messages */

  /* region Hardware Config Commands */
  [HardwareModuleMessages.createHardwareConfigCommandsSuccess]: "Comando(s) de configuração cadastrado(s) com sucesso",
  [HardwareModuleMessages.createHardwareConfigCommandsError]: "Falha ao cadastrar comando(s) de configuração",
  [HardwareModuleMessages.readHardwareConfigCommandsSuccess]: "Comando(s) retornado(s) com sucesso",
  [HardwareModuleMessages.readHardwareConfigCommandsError]: "Falha ao buscar comando(s)",
  [HardwareModuleMessages.updateHardwareConfigCommandsSuccess]: "Comando alterado com sucesso",
  [HardwareModuleMessages.updateHardwareConfigCommandsError]: "Falha ao alterar comando(s)",
  [HardwareModuleMessages.readHardwareBlockCommandsSuccess]: "Bloqueio(s) retornado(s) com sucesso",
  [HardwareModuleMessages.readHardwareConfigCommandsContentSuccess]: "Comando(s) pré definido(s) retornado(s) com"
  + " sucesso",
  [HardwareModuleMessages.readHardwareConfigCommandsContentError]: "Falha ao buscar comando(s) pré definido(s)",
  [HardwareModuleMessages.noCommandFound]: "Nenhum comando cadastrado",
  [HardwareModuleMessages.commandNotFound]: "Comando não cadastrado em nosso sistema",
  [HardwareModuleMessages.noBlockFound]: "Nenhum bloqueio cadastrado",
  [HardwareModuleMessages.readHardwareBlockCommandsError]: "Falha ao buscar bloqueio(s)",
  /* endregion Hardware Config Commands */
  /* region Hardware logs */
  [HardwareModuleMessages.readHardwareLogsSuccess]: "Log(s) retornado(s) com sucesso",
  [HardwareModuleMessages.noLogFound]: "Nenhum log encontrado",
  [HardwareModuleMessages.readHardwareLogsError]: "Falha ao buscar log(s)",
  /* endregion Hardware logs */
  /* region Hardware types */
  [HardwareModuleMessages.typeTracker]: "Rastreador",
  [HardwareModuleMessages.typeIButton]: "Ibutton",
  [HardwareModuleMessages.typeSensor]: "Sensor de balão",
  [HardwareModuleMessages.createHardwareTypesSuccess]: "Tipo de hardware cadastrado com sucesso",
  [HardwareModuleMessages.readHardwareTypesSuccess]: "Tipo(s) de hardware(s) retornado(s) com sucesso",
  [HardwareModuleMessages.updateHardwareTypesSuccess]: "Tipo de hardware alterado com sucesso",
  [HardwareModuleMessages.deleteHardwareTypesSuccess]: "Tipo de hardware excluído com sucesso",
  [HardwareModuleMessages.noHardwareTypeFound]: "Nenhum tipo de hardware cadastrado",
  [HardwareModuleMessages.readHardwareTypesError]: "Falha ao buscar tipo(s) de hardware(s)",
  [HardwareModuleMessages.createHardwareTypeError]: "Falha ao cadastrar tipo de hardware",
  [HardwareModuleMessages.updateHardwareTypeError]: "Falha ao alterar tipo de hardware",
  [HardwareModuleMessages.hardwareTypeNotFound]: "Tipo de hardware não cadastrado em nosso sistema",
  [HardwareModuleMessages.deleteHardwareTypeError]: "Falha ao excluir tipo de hardware",
  /* endregion Hardware types */
  /* region Hardware */
  [HardwareModuleMessages.createHardwareSuccess]: "Hardware cadastrado com sucesso",
  [HardwareModuleMessages.readHardwareSuccess]: "Hardware(s) retornado(s) com sucesso",
  [HardwareModuleMessages.updateHardwareSuccess]: "Hardware alterado com sucesso",
  [HardwareModuleMessages.deleteHardwareSuccess]: "Hardware excluído com sucesso",
  [HardwareModuleMessages.createHardwareError]: "Falha ao cadastrar hardware",
  [HardwareModuleMessages.readHardwareError]: "Falha ao buscar hardware(s)",
  [HardwareModuleMessages.noHardwareFound]: "Nenhum hardware cadastrado",
  [HardwareModuleMessages.updateHardwareError]: "Falha ao editar hardware",
  [HardwareModuleMessages.hardwareNotFound]: "Hardware não cadastrado em nosso sistema",
  [HardwareModuleMessages.deleteHardwareError]: "Falha ao excluir hardware",
  /* endregion Hardware */
  /* region Hardware Installation History */
  [HardwareModuleMessages.readHardwareInstallationHistorySuccess]: "Histórico de instalação retornado com sucesso",
  [HardwareModuleMessages.noHardwareInstallationHistoryFound]: "Nenhum histórico de instalação cadastrado",
  [HardwareModuleMessages.readHardwareInstallationHistoryError]: "Falha ao buscar histórico de instalação"

  /* endregion Backend messages */
};
