import { TranslationZonesModuleMessages, ZonesModuleMessages } from "../interfaces";

export const messages: TranslationZonesModuleMessages = {
  [ZonesModuleMessages.columnName]: "Nombre",
  [ZonesModuleMessages.columnDescription]: "Descripción",
  [ZonesModuleMessages.newRegister]: "Nueva zona de restricción",
  [ZonesModuleMessages.columnActive]: "Activo",
  [ZonesModuleMessages.columnInactive]: "Inactivo",

  [ZonesModuleMessages.readSuccess]: "Zonas leídas correctamente",
  [ZonesModuleMessages.readError]: "Error al leer zonas",
  [ZonesModuleMessages.createSuccess]: "Zonas creadas correctamente",
  [ZonesModuleMessages.updateSuccess]: "Zonas actualizadas correctamente",
  [ZonesModuleMessages.updateError]: "Error al actualizar zonas",
  [ZonesModuleMessages.createError]: "Error al crear zonas",
  [ZonesModuleMessages.noZonesFound]: "No se encontraron zonas"
};
