import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background: #FFF;
  color: black;
  width: 300px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
  width: 100%;

  margin-bottom: 0.25rem;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  font-weight: 400;
  font-size: 0.875rem;
`;
