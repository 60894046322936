import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import * as Yup from "yup";
// region Imports - Material UI
// endregion Imports - Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Tab,
  Tabs
} from "@material-ui/core";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import { ZonesValidity } from "@shared/entities/reg_zones_validity.entity";
import useTranslation from "src/translations/useTranslation";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ButtonTable from "@components/Button/ButtonTable";
// region Imports - Languages
import {
  DataTableMessages,
  GlobalMessages,
  ToastMessages,
  MenuMessages,
  TravelAlertFullSpeedMessage,
  ZonesValidityMessages,
  YupMessages,
  ZonesModuleMessages
} from "@shared/languages/interfaces";
import api from "@services/api";
import { Zones as Zone } from "@shared/entities/reg_zones.entity";
import TabPanel from "@components/TabPanel";
import usePersistedState from "@hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { useSelector } from "react-redux";
import i18n from "i18next";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import { Checkbox, TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
import { ZoneValidityInterface } from "@shared/interfaces/zone-validity.interface";
import { useAuth } from "@hooks/useAuth";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ContainerModalDataTable, Container, ButtonsContainer, CustomButton } from "./styles";
import { Chip } from "@mui/material";

// endregion Imports - Languages

type ZoneValidityModalProps = { zone: Zone, open?: boolean; onClose }

const ZoneValidityModal: React.FC<ZoneValidityModalProps> = ({ zone, open, onClose }) => {

  // region Hooks
  const { t } = useTranslation();
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { screen } = useSelector((screen) => screen);
  const { addToast } = useToast();
  const { user } = useAuth();
  // endregion Hooks
  // region States
  const [openZoneValidations, setOpenZoneValidations] = useState(open ?? false);
  const [activeCheckbox, setActiveCheckbox] = useState(true);
  const [activeZoneCheckbox, setActiveZoneCheckbox] = useState(zone.active ?? true);
  const [zonesValidity, setZonesValidity] = useState<ZonesValidity[]>([] as Array<ZonesValidity>);
  const [currentZoneValidity, setCurrentZoneValidity] = useState<ZonesValidity>();
  const [openDialogConfirmZoneValidity, setOpenDialogConfirmZoneValidity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDay, setCurrentDay] = useState<string | undefined>("");
  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [dynamicTabs, setDynamicTabs] = useState([
    { label: t(ZonesValidityMessages.zoneRuleTitle), disableRipple: true }
  ]);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);

  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);
  // endregion States
  // region Constants
  /* region Forms */
  const formRef = useRef<FormHandles>(null);
  /* endregion Forms */
  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }],
    order: []
  };

  // region Effects
  useEffect(() => {
    if (table.length) {
      table.clear().draw();
    }
  }, [zonesValidity, table]);
  /* endregion Effects */

  const dataTableColumns: DataTableColumns[] = [
    { // Start Time
      title: t(ZonesValidityMessages.columnStartTime),
      data: (zoneValidity: ZonesValidity) => (zoneValidity?.start_time),
      defaultContent: "",
      filterable: true,
      propertyName: "zones.start_time"
    },
    { // End Time
      title: t(ZonesValidityMessages.columnEndTime),
      data: (zoneValidity: ZonesValidity) => (zoneValidity?.end_time),
      defaultContent: "",
      filterable: true,
      propertyName: "zoneValidity.end_time"
    },
    { // License Plate
      title: t(ZonesValidityMessages.columnLicensePlateEnd),
      data: (zoneValidity: ZonesValidity) => (zoneValidity?.license_plate_end),
      defaultContent: "",
      filterable: true,
      propertyName: "zoneValidity.license_plate_end"
    },
    { // Day of Week
      title: t(ZonesValidityMessages.columnDayOfWeek),
      data: (zoneValidity: ZonesValidity) => {
        const day = daysOfWeek.find((d) => d.value === zoneValidity?.day_of_week);

        return day?.label;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "zoneValidity.day_of_week"
    },
    { // Active
      title: "Status",
      filterable: true,
      propertyName: "active",
      data: (zoneValidity: ZonesValidity) => (
        ReactDOMServer.renderToString(
          <b style={{ color: zoneValidity.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {zoneValidity.active ? t(ZonesModuleMessages.columnActive).toUpperCase() : t(ZonesModuleMessages.columnInactive).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (zoneValidity: ZonesValidity) => (
        zoneValidity.active ? t(ZonesModuleMessages.columnActive).toUpperCase() : t(ZonesModuleMessages.columnInactive).toUpperCase()
      )
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-validity-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-validity-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => handleNewValidity()
    }
  ];
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-validity-details",
      callback: (rowData: ZonesValidity) => handleValidityDetails(rowData)
    },
    {
      ref: ".modules-validity-delete",
      callback: (rowData: ZonesValidity) => handleDeleteValidity(rowData)
    }
  ];
  // region Functions
  const daysOfWeek = [
    { value: "0", label: t(ZonesValidityMessages.sunday) },
    { value: "1", label: t(ZonesValidityMessages.monday) },
    { value: "2", label: t(ZonesValidityMessages.tuesday) },
    { value: "3", label: t(ZonesValidityMessages.wednesday) },
    { value: "4", label: t(ZonesValidityMessages.thursday) },
    { value: "5", label: t(ZonesValidityMessages.friday) },
    { value: "6", label: t(ZonesValidityMessages.saturday) }
  ];

  // eslint-disable-next-line @typescript-eslint/ban-types

  const handleSaveValidity = async (formData: ZoneValidityInterface) => {
    try {
      // Define the validation types
      const schema = Yup.object().shape({
        day_of_week: Yup.string().trim().required(t(YupMessages.fieldRequired))
      });

      await schema.validate(formData, { abortEarly: false });
      const selectedDay = daysOfWeek.find((day) => day.label === formData.day_of_week);

      const insertZoneValidity: ZoneValidityInterface = {
        ...formData,
        day_of_week: selectedDay?.value ?? "",
        license_plate_end: formData.license_plate_end ?? "",
        zones: { id_zones: zone.id_zones! }
      };

      if (currentZoneValidity?.id_reg_zones_validity) {
        insertZoneValidity.id_reg_zones_validity = currentZoneValidity.id_reg_zones_validity;
      }

      // Remove properties without value
      Object.keys(insertZoneValidity).forEach((key) => {
        if (insertZoneValidity[key] === undefined || insertZoneValidity[key] === null) {
          delete insertZoneValidity[key];
        }
      });

      if (!currentZoneValidity?.id_reg_zones_validity) {
        await createZoneValidity(insertZoneValidity);
      } else {
        await updateZoneValidity(insertZoneValidity);
      }
    } catch (error) {
      formRef.current?.setErrors(getValidationErrors(error));
    }
  };

  const submitUpdateZone = async () => {
    try{
      const formData = formRef.current?.getData() || {};
      const updateZone = {
        name: formData["name"] || zone.name,
        description: formData["description"] || zone.description,
        active: activeZoneCheckbox.toString()
      };
      const { data } = await api.patch(`zones/update/${zone.id_zones}`, updateZone);

      if (data.status === "success") {

        table.draw();
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        onClose();
      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }

    } catch (error) {
      if (!error.response) {
        addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      } else {
        addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }
    }
  };

  const handleCloseTabZoneValidations = () => {
    setCurrentZoneValidity(undefined);
    setDynamicTabs([{ label: t(ZonesValidityMessages.zoneRuleTitle), disableRipple: true }]);
    setSelectedTab(0);
  };

  const handleValidityDetails = (validity) => {
    const currentTab = { label: t(ZonesValidityMessages.zoneRuleEdit), disableRipple: true };

    setActiveCheckbox(validity.active);
    setDynamicTabs([...dynamicTabs, currentTab]);
    setCurrentZoneValidity(validity);
    setSelectedTab(1);
  };

  const handleNewValidity = () => {
    const currentTab = { label: t(ZonesValidityMessages.newZoneRule), disableRipple: true };

    setCurrentZoneValidity(undefined);

    setDynamicTabs([...dynamicTabs, currentTab]);
    setSelectedTab(1);
  };

  const handleUpdateZone = () => {
    const currentTab = { label: "Editar Zona", disableRipple: true };

    setDynamicTabs([...dynamicTabs, currentTab]);
    setSelectedTab(2);
  };

  const handleDeleteValidity = (validity) => {
    setCurrentZoneValidity(validity);
    setCurrentDay(daysOfWeek.find((d) => d.value === validity?.day_of_week)?.value ?? undefined);
    setOpenDialogConfirmDelete(true);
  };

  const createZoneValidity = useCallback(async (zoneValidity: ZoneValidityInterface) => {

    try {

      const { data } = await api.post("zones-validity/create", zoneValidity);

      if (data.status === "success") {

        table.row.add(zoneValidity).draw();
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        await readZonesValidity();
        setSelectedTab(1);
        handleCloseTabZoneValidations();
      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }
    } catch (error) {
      if (!error.response) {
        addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      } else {
        addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast, table]);

  const updateZoneValidity = useCallback(async (zoneValidity: ZoneValidityInterface) => {

    try {

      const { data } = await api.patch(`zones-validity/update/${zoneValidity.id_reg_zones_validity}`, zoneValidity);

      if (data.status === "success") {
        const updatedValidity = data.result;

        const row = table.row(tableClickedRow);

        row.data(updatedValidity).draw();

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        await readZonesValidity();
        handleCloseTabZoneValidations();
      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }
    } catch (error) {
      if (!error.response) {
        addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      } else {
        addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast, table, handleCloseTabZoneValidations]);

  /**
   * Search zones validity
   */
  const readZonesValidity = useCallback(async () => {

    setLoading(true);

    try {

      // Get the zones
      const { data } = await api.get(`zones-validity/readByZone/${zone.id_zones}`);

      if (data.status === "success") {
        setZonesValidity([...data.result]);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

        setZonesValidity([]);
        if (table.length) table.clear().draw();

      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [t, currentZoneValidity]);

  /** Delete selected Validity
   */
  const deleteZoneValidity = useCallback(async () => {

    try {

      setLoading(true);
      const { data } = await api.delete(`zones-validity/delete/${currentZoneValidity?.id_reg_zones_validity}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false);
        setCurrentZoneValidity(undefined);
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        readZonesValidity();

        // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.row(tableClickedRow).remove();
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, openDialogConfirmDelete, table, tableClickedRow, t]);

  // region Effects

  // Refresh the zones when the language is changed
  useEffect(() => {
    readZonesValidity().then();
  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  // endregion Effects

  return (
    <>
      <ContainerModalDataTable id="dataTable">
        <Dialog
          open={openZoneValidations}
          onClose={onClose}
          fullScreen
          fullWidth
          scroll="paper"
          container={document.getElementById("modalNewZone")}
        >
          <DialogTitle className="mHeader">
            <div className="content" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="title">
                {zone.name ?? ""}
              </div>
              <Chip
                label={zone.active ? "Ativo" : "Inativo"}
                color={zone.active ? "success" : "default"}
              />
              <Button disableRipple onClick={() => handleUpdateZone()}><EditIcon /></Button>
            </div>
          </DialogTitle>
          <DialogContent dividers className="mContent">
            <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
              <Tabs
                value={selectedTab}
                className="tabContainer"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.colors.primary
                  }
                }}
              >
                {dynamicTabs.map((tab, index) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    disableRipple={tab.disableRipple}
                    disabled={index !== selectedTab}
                  />
                ))}
              </Tabs>
              <TabPanel value={selectedTab} index={0}>
                {!loading && (
                  <>
                    <NewDataTable
                      title={t(MenuMessages.zones)}
                      data={zonesValidity}
                      columns={dataTableColumns}
                      settings={dataTableSettings}
                      actions={dataTableActions}
                      showPaging={false}
                      returnTable={(table) => setTable(table)}
                    />
                    <ButtonsContainer>
                      {dataTableButtons.map((button) => (
                        <CustomButton
                          key={button.key}
                          onClick={button.callback}
                        >
                          {t(DataTableMessages.buttonsAddNew)}
                        </CustomButton>
                      ))}
                    </ButtonsContainer>
                  </>
                )}
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <Form className="form" ref={formRef} onSubmit={handleSaveValidity}>
                  <FormControlLabel
                    className="r-checkbox"
                    control={(
                      <Checkbox
                        onChange={(event) => setActiveCheckbox(event.target.checked)}
                        checked={activeCheckbox}
                        name="active"
                      />
                    )}
                    label={t(ZonesValidityMessages.colmunActive)}
                  />
                  <TextField
                    label={t(ZonesValidityMessages.columnStartTime)}
                    margin="dense"
                    variant="outlined"
                    type="time"
                    defaultValue={currentZoneValidity?.start_time ?? ""}
                    name="start_time"
                    fullWidth
                  />
                  <TextField
                    label={t(ZonesValidityMessages.columnEndTime)}
                    margin="dense"
                    variant="outlined"
                    type="time"
                    defaultValue={currentZoneValidity?.end_time ?? ""}
                    name="end_time"
                    fullWidth
                  />
                  <TextField
                    label={t(ZonesValidityMessages.columnLicensePlateEnd)}
                    margin="dense"
                    variant="outlined"
                    type="number"
                    defaultValue={currentZoneValidity?.license_plate_end}
                    name="license_plate_end"
                    fullWidth
                  />
                  <Autocomplete
                    options={daysOfWeek}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setCurrentZoneValidity((prev) => ({
                        ...prev,
                        day_of_week: newValue?.value || ""
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        name="day_of_week"
                        {...params}
                        variant="outlined"
                        label={t(ZonesValidityMessages.columnDayOfWeek)}
                        InputLabelProps={{
                          shrink: !!currentZoneValidity?.day_of_week
                        }}
                      />
                    )}
                    value={daysOfWeek.find((option) => option.value === currentZoneValidity?.day_of_week) || null}
                  />
                  <DialogActions className="mFooter">
                    { selectedTab > 0 && (
                      <Button disableRipple color="primary" type="submit">{t(GlobalMessages.save)}</Button>
                    )}
                    <Button disableRipple onClick={handleCloseTabZoneValidations} color="primary">{t(GlobalMessages.close)}</Button>
                  </DialogActions>
                </Form>
                <DialogConfirmAction
                  open={openDialogConfirmZoneValidity}
                  onClose={() => setOpenDialogConfirmZoneValidity(false)}
                  title={(
                    <>
                      <span>{t(TravelAlertFullSpeedMessage.confirmFullSpeedTravel)}</span>
                      <span className="strong" style={{ color: "red" }}>km/h</span>
                    </>
                  )}
                  actions={[
                    { text: t(GlobalMessages.no).toUpperCase(),
                      action: () => setOpenDialogConfirmZoneValidity(false) },
                    {
                      text: t(GlobalMessages.yes).toUpperCase(),
                      action: async () => {
                        await handleSaveValidity;
                      }
                    }
                  ]}
                >
                  {t(GlobalMessages.confirm)}
                </DialogConfirmAction>
              </TabPanel>
              <TabPanel value={selectedTab} index={1} />
              <TabPanel value={selectedTab} index={2}>
                <>
                  <Form className="form" ref={formRef} onSubmit={submitUpdateZone}>
                    <FormControlLabel
                      className="r-checkbox"
                      control={(
                        <Checkbox
                          onChange={(event) => setActiveZoneCheckbox(event.target.checked)}
                          checked={activeZoneCheckbox}
                          name="active"
                        />
                      )}
                      label={t(ZonesValidityMessages.colmunActive)}
                    />
                    <TextField
                      label={t(ZonesModuleMessages.columnName)}
                      margin="dense"
                      variant="outlined"
                      defaultValue={zone?.name ?? ""}
                      name="name"
                      fullWidth
                    />
                    <TextField
                      label={t(ZonesModuleMessages.columnDescription)}
                      margin="dense"
                      variant="outlined"
                      defaultValue={zone?.description ?? ""}
                      name="description"
                      fullWidth
                      multiline
                      rows={4}
                    />
                    <DialogActions className="mFooter">
                      { selectedTab > 0 && (
                        <Button disableRipple color="primary" type="submit">{t(GlobalMessages.save)}</Button>
                      )}
                      <Button disableRipple onClick={handleCloseTabZoneValidations} color="primary">{t(GlobalMessages.close)}</Button>
                    </DialogActions>
                  </Form>
                  <DialogConfirmAction
                    open={openDialogConfirmZoneValidity}
                    onClose={() => setOpenDialogConfirmZoneValidity(false)}
                    title={(
                      <>
                        <span>{t(TravelAlertFullSpeedMessage.confirmFullSpeedTravel)}</span>
                        <span className="strong" style={{ color: "red" }}>km/h</span>
                      </>
                    )}
                    actions={[
                      { text: t(GlobalMessages.no).toUpperCase(),
                        action: () => setOpenDialogConfirmZoneValidity(false) },
                      {
                        text: t(GlobalMessages.yes).toUpperCase(),
                        action: async () => {
                          await handleSaveValidity;
                        }
                      }
                    ]}
                  >
                    {t(GlobalMessages.confirm)}
                  </DialogConfirmAction>
                </>
              </TabPanel>
              <DialogConfirmAction
                open={openDialogConfirmDelete}
                onClose={() => setOpenDialogConfirmDelete(false)}
                zIndex={30000}
                title={t(ZonesValidityMessages.zoneRuleDeleteConfirmation)}
                actions={[
                  { text: t(GlobalMessages.no), action: () => setOpenDialogConfirmDelete(false) },
                  { text: t(GlobalMessages.yes), action: () => deleteZoneValidity() }
                ]}
              >
                { t(ZonesValidityMessages.zoneRuleDeleteConfirmationText)} <br /><br />
                { t(ZonesValidityMessages.columnDayOfWeek) }: {currentDay} <br />
                { t(ZonesValidityMessages.columnLicensePlateEnd) } {currentZoneValidity?.license_plate_end}?
              </DialogConfirmAction>
            </Container>
          </DialogContent>
        </Dialog>
      </ContainerModalDataTable>
    </>
  );
};

export default ZoneValidityModal;
