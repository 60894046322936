import React, { useCallback, useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";

// region Imports - Material UI
import Map from "@material-ui/icons/Map";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
// endregion Imports - Material UI
// region Imports - External libraries
import * as Yup from "yup";
// endregion Imports - External libraries
// region Imports - Unform
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
// endregion Imports - Unform
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  ZonesModuleMessages,
  ToastMessages,
  YupMessages, MenuMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Zones as Zone } from "@shared/entities/reg_zones.entity";
// endregion Imports - Shared
// region Imports - Atoms
import FileInput from "@atoms/FileInput";
// endregion Imports - Atoms
// region Imports - Organisms
import MapFixedRegion from "@organisms/MapFixedRegion";
// endregion Imports - Organisms
// region Imports - Components
import ButtonTable from "@components/Button/ButtonTable";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ZoneValidityModal from "@components/Modal/ZoneValidity";
import { Container, ContainerModalMap, ContainerModalNewRegister } from "./styles";
// endregion Imports - Styles

// region Interfaces
interface ImportFormData {
  file: File;
}
// endregion Interfaces

const Zones: React.FC = () => {

  // region States
  const [zones, setZones] = useState<Zone[]>([] as Array<Zone>);

  const [zoneDetails, setZoneDetails] = useState<Zone>({} as Zone);

  const [openMapModal, setOpenMapModal] = useState(false);

  const [openNewRegisterModal, setOpenNewRegisterModal] = useState(false);

  const [openZoneValidations, setOpenZoneValidations] = useState(false);

  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  // endregion States
  // region Hooks
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t, i18n } = useTranslation();
  // endregion Hooks
  // region Constants
  const maxFileSizeInMb = 5;

  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }],
    order: []
  };

  const dataTableColumns: DataTableColumns[] = [
    { // Name
      title: t(ZonesModuleMessages.columnName),
      data: (zone: Zone) => (zone?.name),
      defaultContent: "",
      filterable: true,
      propertyName: "zone.name"
    },
    { // Description
      title: t(ZonesModuleMessages.columnDescription),
      width: "35%",
      data: (zone: Zone) => {
        try {
          if (!zone?.description) return "";

          const parsed = JSON.parse(zone.description);
          let text = parsed?.value ?? "";

          // Replace multiple line breaks with a single one
          text = text.replace(/(<br\s*\/?>|\n)+/gi, " ").trim();

          return text;
        } catch {
          return zone?.description ?? "";
        }
      },
      defaultContent: "",
      filterable: true,
      propertyName: "zone.description"
    },
    { // Active
      title: "Status",
      filterable: true,
      propertyName: "active",
      data: (zone: Zone) => (
        ReactDOMServer.renderToString(
          <b style={{ color: zone.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {zone.active ? t(ZonesModuleMessages.columnActive).toUpperCase() : t(ZonesModuleMessages.columnInactive).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (zone: Zone) => (
        zone.active ? t(ZonesModuleMessages.columnActive).toUpperCase() : t(ZonesModuleMessages.columnInactive).toUpperCase()
      )
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-zone-list-details"><Map /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-zone-list-validty"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];

  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => handleZoneNewRegister()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_programacoes_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-zone-list-details",
      callback: (rowData: Zone) => handleZoneDetails(rowData)
    },
    {
      ref: ".modules-zone-list-validty",
      callback: (rowData: Zone) => handleZoneValidity(rowData)
    }
  ];
  // endregion Constants
  // region Form Validation
  const formRef = useRef<FormHandles>(null);
  // Validations of unique fields
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: ImportFormData) => {

      try {

        // Define the validation types
        const schema = Yup.object().shape({
          file: Yup.mixed()
            .test("hasFile", t(YupMessages.fieldRequired), (file: File | null) => file !== null)
            .test("fileSize", t(YupMessages.largeFileSize).replace("FILE_SIZE", `${maxFileSizeInMb}MB`), (file: File | null) => {
              if (!file) return true;

              return file.size <= maxFileSizeInMb * 1024 * 1024;
            })
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update zone (According action selected)
        await createZone(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  // endregion Form Validation
  // region Functions
  /**
   * Search zones
   */
  const readZones = useCallback(async () => {

    setLoading(true);

    try {

      // Get the zones
      const { data } = await api.get("zones/read");

      if (data.status === "success") {

        setZones([...data.result] as Zone[]);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        setZones([]);
        if (table.length) table.clear().draw();
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, table, t]);

  /**
   * Create a new zone
   * @param zone The zone to create
   */
  const createZone = async (zone: ImportFormData) => {

    setLoading(true);

    try {

      const formData = new FormData();

      formData.append("file", zone.file);

      const { data } = await api.post("zones/create", formData,
        { headers: { "Content-Type": "multipart/form-data" } });

      if (data.status === "success") {

        await readZones();

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        handleCloseZoneNewRegister();

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }

    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle the zone details
   */
  const handleZoneDetails = async (zone: Zone) => {
    setOpenMapModal(true);
    setZoneDetails(zone);
  };

  const handleZoneValidity = async (zone: Zone) => {
    setZoneDetails(zone);
    setOpenZoneValidations(true);
  };
  /**
   * Handle the zone new register
   */
  const handleZoneNewRegister = async () => setOpenNewRegisterModal(true);

  /**
   * Handle close the zone new register
   */
  const handleCloseZoneNewRegister = () => setOpenNewRegisterModal(false);

  const handleCloseModal = () => {
    setOpenZoneValidations(false);
    readZones();
  };

  // endregion Functions
  // region Effects

  // Refresh the zones when the language is changed
  useEffect(() => {
    readZones().then();
  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  // endregion Effects

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform}>
        <Loading loading={loading} />
        { !loading && (
          <NewDataTable
            title={t(MenuMessages.zones)}
            data={zones}
            columns={dataTableColumns}
            settings={dataTableSettings}
            actions={dataTableActions}
            buttons={dataTableButtons}
            returnTable={(table) => setTable(table)}
          />
        )}
        <ContainerModalNewRegister id="modalNewZone">
          <Dialog
            open={openNewRegisterModal}
            onClose={handleCloseZoneNewRegister}
            scroll="paper"
            container={document.getElementById("modalNewZone")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {t(ZonesModuleMessages.newRegister)}
                </div>
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <DialogContentText tabIndex={-1} component="div">
                <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData)}>
                  <DialogContentText tabIndex={-1} component="div">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12}>
                        <FileInput
                          name="file"
                          helperText={t(GlobalMessages.required)}
                          accept=".kml"
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </Form>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mFooter">
              <Button disableRipple onClick={() => formRef.current?.submitForm()} color="primary">{t(GlobalMessages.save)}</Button>
              <Button disableRipple onClick={handleCloseZoneNewRegister} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalNewRegister>
        <ContainerModalMap id="modalMapMarkerLocation">
          <Dialog
            open={openMapModal}
            onClose={() => setOpenMapModal(false)}
            scroll="paper"
            container={document.getElementById("modalMapMarkerLocation")}
          >
            <DialogContent dividers className="mContent">
              <DialogContentText tabIndex={-1} component="div">
                <MapFixedRegion
                  mapHeight={500}
                  geometry={zoneDetails.geom!}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mFooter">
              <Button disableRipple onClick={() => setOpenMapModal(false)} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalMap>
        {
          openZoneValidations && (
            <>
              <ZoneValidityModal
                zone={zoneDetails}
                open={openZoneValidations}
                onClose={handleCloseModal}
              />
            </>
          )
        }
      </Container>
    </>
  );
};

export default Zones;
