import { TravelHistoryMessages, TranslationTravelHistoryMessages } from "../interfaces";

export const messages: TranslationTravelHistoryMessages = {
  [TravelHistoryMessages.time]: "tiempo",
  [TravelHistoryMessages.distance]: "Distancia",
  [TravelHistoryMessages.durationInMinutes]: "Duración (en minutos)",
  [TravelHistoryMessages.distanceInKilometers]: "Distancia (en kilómetros)",
  [TravelHistoryMessages.document]: "Doc.",
  [TravelHistoryMessages.origin]: "Origen",
  [TravelHistoryMessages.destination]: "Destino",
  [TravelHistoryMessages.finishPoint]: "Punto final",
  [TravelHistoryMessages.travelHistoryTitle]: "Historial de viajes",
  [TravelHistoryMessages.travels]: "Viajes",
  [TravelHistoryMessages.pumpReportTitle]: "Informe de bomba",
  [TravelHistoryMessages.coordinatesTitle]: "Coordenadas",
  [TravelHistoryMessages.on]: "Encendido",
  [TravelHistoryMessages.off]: "Apagado",
  [TravelHistoryMessages.occurrenceDate]: "Fecha de ocurrencia",
  [TravelHistoryMessages.status]: "Status",
  [TravelHistoryMessages.ignition]: "Motor",
  [TravelHistoryMessages.speed]: "Velocidad",
  [TravelHistoryMessages.latitude]: "Latitud",
  [TravelHistoryMessages.longitude]: "Longitud",
  [TravelHistoryMessages.address]: "Dirección",
  [TravelHistoryMessages.average]: "Promedio",
  [TravelHistoryMessages.movingAverage]: "Promedio móvil",
  [TravelHistoryMessages.records]: "registros",
  [TravelHistoryMessages.averageSpeed]: "Velocidad media",
  [TravelHistoryMessages.registrationDate]: "Fecha de registro",
  [TravelHistoryMessages.intervalBetweenLastCommand]: "Intervalo",
  [TravelHistoryMessages.analyticReport]: "Informe analítico",
  [TravelHistoryMessages.syntheticReport]: "Informe sintético",
  [TravelHistoryMessages.vehiclePlate]: "Placa",
  [TravelHistoryMessages.vehicleType]: "Tipo de vehículo",
  [TravelHistoryMessages.plant]: "Planta",
  [TravelHistoryMessages.client]: "Cliente",
  [TravelHistoryMessages.constructionEntryDate]: "Fecha de entrada a la construcción",
  [TravelHistoryMessages.constructionLeaveDate]: "Fecha de salida de la construcción",
  [TravelHistoryMessages.durationOnConstruction]: "Duración en la construcción",
  [TravelHistoryMessages.totalPumpingDuration]: "Duración total de bombeo",
  [TravelHistoryMessages.totalIdleDuration]: "Duración total de inactividad",
  [TravelHistoryMessages.regional]: "Regional",
  [TravelHistoryMessages.group]: "Grupo",
  [TravelHistoryMessages.addressNotFound]: "Dirección no encontrada",
  [TravelHistoryMessages.integrationReport]: "Informe de integración",
  [TravelHistoryMessages.mixerVehicleCode]: "Código de vehículo mezclador",
  [TravelHistoryMessages.noMixerRelated]: "No hay mezclador relacionado",
  [TravelHistoryMessages.documentNumber]: "Número de documento",
  [TravelHistoryMessages.startDateTransport]: "Fecha de inicio de transporte",
  [TravelHistoryMessages.endDateTransport]: "Fecha de fin de transporte",
  [TravelHistoryMessages.durationTransport]: "Duración de transporte",
  [TravelHistoryMessages.startDateLocation]: "Fecha de inicio de ubicación",
  [TravelHistoryMessages.endDateLocation]: "Fecha de fin de ubicación",
  [TravelHistoryMessages.durationLocation]: "Duración de ubicación",
  [TravelHistoryMessages.startDateUnload]: "Fecha de inicio de descarga",
  [TravelHistoryMessages.endDateUnload]: "Fecha de fin de descarga",
  [TravelHistoryMessages.durationUnload]: "Duración de descarga",
  [TravelHistoryMessages.startDateReturn]: "Fecha de inicio de retorno",
  [TravelHistoryMessages.endDateReturn]: "Fecha de fin de retorno",
  [TravelHistoryMessages.durationReturn]: "Duración de retorno"
};
