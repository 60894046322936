import { HardwareModuleMessages, TranslationHardwareModuleMessage } from "../interfaces";

export const messages: TranslationHardwareModuleMessage = {

  /* region Frontend messages */
  [HardwareModuleMessages.tabsHardware]: "Hardware",
  [HardwareModuleMessages.tabsHardwareInstallationHistory]: "Installation history",
  [HardwareModuleMessages.tabsCommands]: "Commands",
  [HardwareModuleMessages.tabsLogs]: "Logs",
  [HardwareModuleMessages.tabsMaintenanceHistoru]: "Maintenance history",

  /* region Commands tab */
  [HardwareModuleMessages.commandStatusPending]: "Pending",
  [HardwareModuleMessages.commandStatusSent]: "Sent",
  [HardwareModuleMessages.commandStatusProcessed]: "Processed",
  [HardwareModuleMessages.commandStatusCancelled]: "Cancelled",
  [HardwareModuleMessages.commandFilterCreation]: "Creation",
  [HardwareModuleMessages.commandFilterSend]: "Send",
  [HardwareModuleMessages.commandFilterProcessing]: "Processing",
  [HardwareModuleMessages.command]: "Command",
  [HardwareModuleMessages.commandRegistrationDate]: "Registration date",
  [HardwareModuleMessages.commandSendDate]: "Send date",
  [HardwareModuleMessages.commandProcessedDate]: "Processed date",
  [HardwareModuleMessages.commandAttempts]: "Attempts",
  [HardwareModuleMessages.commandResponse]: "Response",
  [HardwareModuleMessages.commandFormSelectVehicle]: "Select the vehicle(s) to send command",
  [HardwareModuleMessages.commandFormSelectCommand]: "Select or type the command to be sent",
  [HardwareModuleMessages.commandFormRequiredCommand]: "This field is required",
  [HardwareModuleMessages.commandFilterNoTracker]: "No tracker",
  [HardwareModuleMessages.commandFilterDateRange]: "End date must be greater than start date",
  [HardwareModuleMessages.commandFilterNoLinkedTracker]: "No linked tracker",
  [HardwareModuleMessages.commandTitle]: "Command",
  [HardwareModuleMessages.vehicle]: "Vehicle",
  [HardwareModuleMessages.commandStatus]: "Status",
  [HardwareModuleMessages.commandFilterDateBy]: "Filter date by",
  [HardwareModuleMessages.startDate]: "Start date",
  [HardwareModuleMessages.finishDate]: "Finish date",
  [HardwareModuleMessages.dataTableTitle]: "configuration commands",
  [HardwareModuleMessages.commandDeleteTitle]: "Delete confirmation",
  [HardwareModuleMessages.commandDeleteText]: "Are you sure you want to cancel this command?",
  [HardwareModuleMessages.devId]: "Dev ID",
  [HardwareModuleMessages.commandCreateTitle]: "Create configuration command",
  [HardwareModuleMessages.commandCreateDefinedCommand]: "Predefined command",
  [HardwareModuleMessages.commandCreateToSendCommand]: "Command to be sent - Use the <DEV_ID> tag to"
  + " autocomplete",
  [HardwareModuleMessages.commandCreateNoVehicle]: "Select the vehicles to send the commands above",
  [HardwareModuleMessages.commandCopyAlert]: "Command copied to clipboard",
  /* endregion Commands tab */
  /* region Hardware tab */
  [HardwareModuleMessages.hardwareFormTypeRequired]: "Type is required",
  [HardwareModuleMessages.hardwareFormBrandRequired]: "Brand is required",
  [HardwareModuleMessages.hardwareFormModelRequired]: "Model is required",
  [HardwareModuleMessages.hardwareFormDevIdRequired]: "DEV ID is required",
  [HardwareModuleMessages.hardwareFormPurchaseDateRequired]: "Purchase date is required",
  [HardwareModuleMessages.hardwareFormDevIdAlreadyExists]: "DEV ID already registered",
  [HardwareModuleMessages.iButton]: "Ibutton",
  [HardwareModuleMessages.devId]: "DEV ID",
  [HardwareModuleMessages.type]: "Type",
  [HardwareModuleMessages.brand]: "Brand",
  [HardwareModuleMessages.model]: "Model",
  [HardwareModuleMessages.doBlock]: "Do blocking",
  [HardwareModuleMessages.registrationDate]: "Registration date",
  [HardwareModuleMessages.hardwareDataTableNoLinkedVehicle]: "No linked vehicle",
  [HardwareModuleMessages.simCard]: "SIM Card",
  [HardwareModuleMessages.hardwareDataTableNoLinkedSimCard]: "No linked SIM Card",
  [HardwareModuleMessages.driver]: "Driver",
  [HardwareModuleMessages.hardwareDataTableNoLinkedDriver]: "No linked driver",
  [HardwareModuleMessages.hardwareDataTableTitle]: "hardwares",
  [HardwareModuleMessages.hardwareDeleteTitle]: "Deletion confirmation",
  [HardwareModuleMessages.hardwareDeleteText]: "Do you really want to delete the hardware",
  [HardwareModuleMessages.hardwareCreateTitle]: "Hardware registration",
  [HardwareModuleMessages.hardwareDetailsActive]: "Active hardware",
  [HardwareModuleMessages.hardwareDetailsType]: "Hardware type",
  [HardwareModuleMessages.hardwareDetailsPurchaseDate]: "Purchase date",
  /* endregion Hardware tab */
  /* region Logs tab */
  [HardwareModuleMessages.logFormModelRequired]: "Model is required",
  [HardwareModuleMessages.logNoTracker]: "No tracker",
  [HardwareModuleMessages.logTitle]: "Communications log",
  [HardwareModuleMessages.logAutoRefresh]: "Auto refresh",
  [HardwareModuleMessages.logSearching]: "Searching logs...",
  /* endregion Logs tab */
  /* region Hardware Installation History tab */
  [HardwareModuleMessages.hardwareDevId]: "Hardware DEV ID",
  [HardwareModuleMessages.hardwareType]: "Hardware type",
  [HardwareModuleMessages.occurrenceDate]: "Occurrence date",
  [HardwareModuleMessages.importedData]: "Imported data",
  /* endregion Hardware Installation History tab */

  /* endregion Frontend messages */
  /* region Backend messages */

  /* region Hardware Config Commands */
  [HardwareModuleMessages.createHardwareConfigCommandsSuccess]: "Configuration command(s) registered successfully",
  [HardwareModuleMessages.createHardwareConfigCommandsError]: "Failed to register configuration command(s)",
  [HardwareModuleMessages.readHardwareConfigCommandsSuccess]: "Command(s) returned successfully",
  [HardwareModuleMessages.readHardwareConfigCommandsError]: "Failed to fetch command(s)",
  [HardwareModuleMessages.updateHardwareConfigCommandsSuccess]: "Command edited successfully",
  [HardwareModuleMessages.updateHardwareConfigCommandsError]: "Failed to edit command(s)",
  [HardwareModuleMessages.readHardwareBlockCommandsSuccess]: "Block(s) returned successfully",
  [HardwareModuleMessages.readHardwareConfigCommandsContentSuccess]: "Predefined command(s) returned successfully",
  [HardwareModuleMessages.readHardwareConfigCommandsContentError]: "Failed to fetch predefined command(s)",
  [HardwareModuleMessages.noCommandFound]: "No command registered",
  [HardwareModuleMessages.commandNotFound]: "Command not registered in our system",
  [HardwareModuleMessages.noBlockFound]: "No block registered",
  [HardwareModuleMessages.readHardwareBlockCommandsError]: "Failed to fetch block(s)",
  /* endregion Hardware Config Commands */
  /* region Hardware logs */
  [HardwareModuleMessages.readHardwareLogsSuccess]: "Log(s) returned successfully",
  [HardwareModuleMessages.noLogFound]: "No log registered",
  [HardwareModuleMessages.readHardwareLogsError]: "Failed to fetch log(s)",
  /* endregion Hardware logs */
  /* region Hardware types */
  [HardwareModuleMessages.typeTracker]: "Tracker",
  [HardwareModuleMessages.typeIButton]: "Ibutton",
  [HardwareModuleMessages.typeSensor]: "Drum Sensor",
  [HardwareModuleMessages.createHardwareTypesSuccess]: "Hardware type(s) registered successfully",
  [HardwareModuleMessages.readHardwareTypesSuccess]: "Hardware type(s) returned successfully",
  [HardwareModuleMessages.updateHardwareTypesSuccess]: "Hardware type modified successfully",
  [HardwareModuleMessages.deleteHardwareTypesSuccess]: "Hardware type deleted successfully",
  [HardwareModuleMessages.noHardwareTypeFound]: "No hardware type registered",
  [HardwareModuleMessages.readHardwareTypesError]: "Failed to fetch hardware type(s)",
  [HardwareModuleMessages.createHardwareTypeError]: "Failed to register hardware type",
  [HardwareModuleMessages.updateHardwareTypeError]: "Failed to edit hardware type",
  [HardwareModuleMessages.hardwareTypeNotFound]: "Hardware type not registered in our system",
  [HardwareModuleMessages.deleteHardwareTypeError]: "Failed to delete hardware type",
  /* endregion Hardware types */
  /* region Hardware */
  [HardwareModuleMessages.createHardwareSuccess]: "Hardware registered successfully",
  [HardwareModuleMessages.readHardwareSuccess]: "Hardware(s) returned successfully",
  [HardwareModuleMessages.updateHardwareSuccess]: "Hardware modified successfully",
  [HardwareModuleMessages.deleteHardwareSuccess]: "Hardware deleted successfully",
  [HardwareModuleMessages.createHardwareError]: "Failed to register hardware",
  [HardwareModuleMessages.readHardwareError]: "Failed to fetch hardware(s)",
  [HardwareModuleMessages.noHardwareFound]: "No hardware registered",
  [HardwareModuleMessages.updateHardwareError]: "Failed to edit hardware",
  [HardwareModuleMessages.hardwareNotFound]: "Hardware not registered in our system",
  [HardwareModuleMessages.deleteHardwareError]: "Failed to delete hardware",
  /* endregion Hardware */
  /* region Hardware Installation History */
  [HardwareModuleMessages.readHardwareInstallationHistorySuccess]: "Hardware installation history returned successfully",
  [HardwareModuleMessages.noHardwareInstallationHistoryFound]: "No hardware installation history registered",
  [HardwareModuleMessages.readHardwareInstallationHistoryError]: "Failed to fetch hardware installation history"
  /* endregion Hardware Installation History */

  /* endregion Backend messages */
};
