export enum ZonesValidityMessages {

  columnStartTime = "zonesValidity.columnStartTime",
  columnEndTime = "zonesValidity.columnEndTime",
  columnLicensePlateEnd = "zonesValidity.columnLicensePlateEnd",
  columnDayOfWeek = "zonesValidity.columnDayOfWeek",
  colmunActive = "zonesValidity.colmunActive",

  sunday = "zonesValidity.sunday",
  monday = "zonesValidity.monday",
  tuesday = "zonesValidity.tuesday",
  wednesday = "zonesValidity.wednesday",
  thursday = "zonesValidity.thursday",
  friday = "zonesValidity.friday",
  saturday = "zonesValidity.saturday",

  zoneRuleEdit = "zonesValidity.zoneRuleEdit",
  zoneRuleTitle = "zonesValidity.zoneRuleTitle",
  newZoneRule = "zonesValidity.newZoneRule",
  zoneRuleDeleteConfirmationText = "zonesValidity.zoneRuleDeleteConfirmationText",
  zoneRuleDeleteConfirmation = "zonesValidity.zoneRuleDeleteConfirmation",


  // Zones backend messages
  readSuccess = "zonesValidity.readSuccess",
  readError = "zonesValidity.readError",
  createSuccess = "zonesValidity.createSuccess",
  updateSuccess = "zonesValidity.updateSuccess",
  updateError = "zonesValidity.updateError",
  createError = "zonesValidity.createError",
  noZonesFound = "zonesValidity.noZonesFound",
  deleteSuccess = "zonesValidity.deleteSuccess",
  deleteError = "zonesValidity.deleteError"


}

export type TranslationZonesValidityMessages = {
  [key in ZonesValidityMessages]: string;
}
