import { TravelHistoryMessages, TranslationTravelHistoryMessages } from "../interfaces";

export const messages: TranslationTravelHistoryMessages = {
  [TravelHistoryMessages.time]: "tempo",
  [TravelHistoryMessages.distance]: "Distância",
  [TravelHistoryMessages.durationInMinutes]: "Duração (em minutos)",
  [TravelHistoryMessages.distanceInKilometers]: "Distância (em quilômetros)",
  [TravelHistoryMessages.document]: "Doc.",
  [TravelHistoryMessages.origin]: "Origem",
  [TravelHistoryMessages.destination]: "Destino",
  [TravelHistoryMessages.finishPoint]: "Ponto final",
  [TravelHistoryMessages.travelHistoryTitle]: "Histórico de viagens",
  [TravelHistoryMessages.travels]: "Viagens",
  [TravelHistoryMessages.pumpReportTitle]: "Histórico de bombas",
  [TravelHistoryMessages.client]: "Cliente",
  [TravelHistoryMessages.constructionEntryDate]: "Data de entrada na obra",
  [TravelHistoryMessages.constructionLeaveDate]: "Data de saída da obra",
  [TravelHistoryMessages.durationOnConstruction]: "Duração na obra",
  [TravelHistoryMessages.totalPumpingDuration]: "Duração total de bombeamento",
  [TravelHistoryMessages.totalIdleDuration]: "Duração total de inatividade",
  [TravelHistoryMessages.coordinatesTitle]: "Coordenadas",
  [TravelHistoryMessages.on]: "Ligada",
  [TravelHistoryMessages.off]: "Desligada",
  [TravelHistoryMessages.occurrenceDate]: "Data de ocorrência",
  [TravelHistoryMessages.status]: "Status",
  [TravelHistoryMessages.ignition]: "Ignição",
  [TravelHistoryMessages.speed]: "Velocidade",
  [TravelHistoryMessages.latitude]: "Latitude",
  [TravelHistoryMessages.longitude]: "Longitude",
  [TravelHistoryMessages.address]: "Endereço",
  [TravelHistoryMessages.average]: "Média",
  [TravelHistoryMessages.movingAverage]: "Média móvel",
  [TravelHistoryMessages.records]: "registros",
  [TravelHistoryMessages.averageSpeed]: "Velocidade média",
  [TravelHistoryMessages.registrationDate]: "Data de registro",
  [TravelHistoryMessages.intervalBetweenLastCommand]: "Intervalo",
  [TravelHistoryMessages.analyticReport]: "Relatório analítico",
  [TravelHistoryMessages.syntheticReport]: "Relatório sintético",
  [TravelHistoryMessages.vehiclePlate]: "Placa",
  [TravelHistoryMessages.vehicleType]: "Tipo de veículo",
  [TravelHistoryMessages.plant]: "Planta",
  [TravelHistoryMessages.regional]: "Regional",
  [TravelHistoryMessages.group]: "Grupo",
  [TravelHistoryMessages.addressNotFound]: "Endereço não encontrado",
  [TravelHistoryMessages.integrationReport]: "Relatório de integração",
  [TravelHistoryMessages.mixerVehicleCode]: "Código da Betoneira",
  [TravelHistoryMessages.noMixerRelated]: "Sem viagens de betoneiras relacionadas",
  [TravelHistoryMessages.documentNumber]: "Núm do documento",
  [TravelHistoryMessages.startDateTransport]: "Data de início - A caminho",
  [TravelHistoryMessages.endDateTransport]: "Data de término - A caminho",
  [TravelHistoryMessages.durationTransport]: "Duração - A caminho",
  [TravelHistoryMessages.startDateLocation]: "Data de início - Em Obra",
  [TravelHistoryMessages.endDateLocation]: "Data de término - Em Obra",
  [TravelHistoryMessages.durationLocation]: "Duração - Em Obra",
  [TravelHistoryMessages.startDateUnload]: "Data de início da descarga",
  [TravelHistoryMessages.endDateUnload]: "Data de término da descarga",
  [TravelHistoryMessages.durationUnload]: "Duração da descarga",
  [TravelHistoryMessages.startDateReturn]: "Data de início do retorno",
  [TravelHistoryMessages.endDateReturn]: "Data de término do retorno",
  [TravelHistoryMessages.durationReturn]: "Duração do retorno"
};
