import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "Na usina",
  [VehiclesStatesMessages.onTheWay]: "A caminho",
  [VehiclesStatesMessages.atTheConstructionSite]: "Em obra",
  [VehiclesStatesMessages.unloading]: "Descarregando",
  [VehiclesStatesMessages.returning]: "Retornando",
  [VehiclesStatesMessages.atDestination]: "No destino",
  [VehiclesStatesMessages.inTrustedLocation]: "Em local confiável",
  [VehiclesStatesMessages.pumping]: "Bombeando",
  [VehiclesStatesMessages.idle]: "Ociosa",

  [VehiclesStatesMessages.columnStartDate]: "Início",
  [VehiclesStatesMessages.columnEndDate]: "Fim",
  [VehiclesStatesMessages.columnDuration]: "Duração",
  [VehiclesStatesMessages.columnGroup]: "Grupo",
  [VehiclesStatesMessages.columnRegional]: "Regional",
  [VehiclesStatesMessages.columnPlant]: "Usina",
  [VehiclesStatesMessages.columnVehicleType]: "Tipo de veículo",
  [VehiclesStatesMessages.columnVehicle]: "Veículo",
  [VehiclesStatesMessages.columnVehicleLicensePlate]: "Placa",
  [VehiclesStatesMessages.columnVehicleLastSendDate]: "Última transmissão",
  [VehiclesStatesMessages.columnVehicleStateType]: "Estado",
  [VehiclesStatesMessages.columnLocation]: "Local",
  [VehiclesStatesMessages.columnLocationType]: "Tipo de local",
  [VehiclesStatesMessages.columnCurrentLatitude]: "Latitude atual",
  [VehiclesStatesMessages.columnCurrentLongitude]: "Longitude atual",
  [VehiclesStatesMessages.columnCurrentAddress]: "Endereço atual",

  [VehiclesStatesMessages.readVehicleStateHistorySuccess]: "Histórico de estados de veículo lido com sucesso",
  [VehiclesStatesMessages.noVehicleStateHistoryFound]: "Nenhum histórico de estados de veículo encontrado",
  [VehiclesStatesMessages.readVehicleStateHistoryError]: "Erro ao ler histórico de estados de veículo"
};
