export enum ZonesModuleMessages {

  columnName = "zonesModule.columnName",
  columnDescription = "zonesModule.columnDescription",
  columnActive = "zonesModule.columnActive",
  columnInactive = "zonesModule.columnInactive",
  newRegister = "zonesModule.newRegister",

  // Zones backend messages
  readSuccess = "zonesModule.readSuccess",
  readError = "zonesModule.readError",
  createSuccess = "zonesModule.createSuccess",
  createError = "zonesModule.createError",
  updateSuccess = "zonesModule.updateSuccess",
  updateError = "zonesModule.updateError",
  noZonesFound = "zonesModule.noZonesFound"

}

export type TranslationZonesModuleMessages = {
  [key in ZonesModuleMessages]: string;
}
