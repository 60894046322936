import React, { ReactElement } from "react";

// region Libraries
import { withStyles} from "@material-ui/core";
import { Tooltip } from "@mui/material";
import * as dateFns from "date-fns";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  AlertTypeMessages,
  PumpTimelineMessages,
  VehiclesStatesTypesMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Utils
import utils from "@utils/useful-functions";
// endregion Utils
// region Interfaces
import { Alert } from "@shared/interfaces/alert.interface";
import { AlertTypesID } from "@shared/constants/alert-types.enum";
// endregion Interfaces
// region Styles
import { Container, Content, ContentItem, IconContainer, Title } from "./styles";
// endregion Styles

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#ffffff !important",
    color: "#000",
    padding: "0 !important",
    borderRadius: "0.5rem !important"
  },
  arrow: {
    color: "#ffffff !important"
  }
}))(Tooltip);

type TimelineAlertTooltipProps = {
  children: ReactElement,
  isOpen: boolean,
  alert: Alert,
  onClick: (alert: Alert) => void,
  speedThreshold: number
};
const TimelineAlertTooltip: React.FC<TimelineAlertTooltipProps> = ({ children, onClick, alert, isOpen, speedThreshold }) => {
  const { t } = useTranslation();

  return (
    <StyledTooltip
      arrow
      placement="top"
      onClick={() => onClick(alert)}
      open={isOpen}
      PopperProps={{
        disablePortal: true
      }}
      title={
        <Container onClick={() => onClick(alert)}>
          <IconContainer>{ utils.getAlertIconAccordingType(alert.type.id_alert_type) }</IconContainer>
          <Content>
            <Title>{t(AlertTypeMessages[alert.type.id_alert_type])}</Title>
            {
              alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE && (
                <ContentItem>
                  <p>{t(PumpTimelineMessages.tooltipSpeedThreshold)}</p>
                  <span style={{ color: "red" }} >{speedThreshold} ► {Math.round(alert.event?.speed ?? 0)} km/h</span>
                </ContentItem>
              )
            }
            <ContentItem>
              <div>
                {t(PumpTimelineMessages.tooltipGenerateAt) + " "}
                <span style={{ color: utils.getStateColor(alert.vehicle_state?.status.id_vehicle_state_type ?? "") }}>
                  { t(VehiclesStatesTypesMessages[alert.vehicle_state?.status.id_vehicle_state_type ?? ""]).toLowerCase() }
                </span>
              </div>
              <span>{ dateFns.format(new Date(alert.occurrence_date), "HH'h'mm") }</span>
            </ContentItem>
          </Content>
        </Container>
      }>
      { children }
    </StyledTooltip>

  );
};

export default TimelineAlertTooltip;
