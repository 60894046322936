import { ReportTypes } from "../../constants/report-types.enum";

export enum TravelHistoryMessages {
  time = "travelHistory.time",
  distance = "travelHistory.distance",
  durationInMinutes = "travelHistory.durationInMinutes",
  distanceInKilometers = "travelHistory.distanceInKilometers",
  document = "travelHistory.document",
  origin = "travelHistory.origin",
  destination = "travelHistory.destination",
  finishPoint = "travelHistory.finishPoint",
  travelHistoryTitle = "travelHistory.travelHistoryTitle",
  travels = "travelHistory.historyTitle",
  pumpReportTitle = "travelHistory.pumpReportTitle",
  coordinatesTitle = "travelHistory.coordinatesTitle",
  on = "travelHistory.on",
  off = "travelHistory.off",
  occurrenceDate = "travelHistory.occurrenceDate",
  status = "travelHistory.status",
  ignition = "travelHistory.ignition",
  speed = "travelHistory.speed",
  latitude = "travelHistory.latitude",
  longitude = "travelHistory.longitude",
  address = "travelHistory.address",
  average = "travelHistory.average",
  movingAverage = "travelHistory.movingAverage",
  records = "travelHistory.records",
  averageSpeed = "travelHistory.averageSpeed",
  registrationDate = "travelHistory.registrationDate",
  intervalBetweenLastCommand = "travelHistory.intervalBetweenLastCommand",
  analyticReport = "travelHistory.analyticReport",
  syntheticReport = "travelHistory.syntheticReport",
  vehiclePlate = "travelHistory.vehiclePlate",
  vehicleType = "travelHistory.vehicleType",
  plant = "travelHistory.plant",
  regional = "travelHistory.regional",
  group = "travelHistory.group",
  addressNotFound = "travelHistory.addressNotFound",
  integrationReport = "travelHistory.integrationReport",
  client = "travelHistory.client",
  constructionEntryDate = "travelHistory.constructionEntryDate",
  constructionLeaveDate = "travelHistory.constructionLeaveDate",
  durationOnConstruction = "travelHistory.durationOnConstruction",
  totalPumpingDuration = "travelHistory.totalPumpingDuration",
  totalIdleDuration = "travelHistory.totalIdleDuration",
  mixerVehicleCode = "travelHistory.mixerVehicleCode",
  noMixerRelated = "travelHistory.noMixerRelated",
  documentNumber = "travelHistory.documentNumber",
  startDateTransport = "travelHistory.startDateTransport",
  endDateTransport = "travelHistory.endDateTransport",
  durationTransport = "travelHistory.durationTransport",
  startDateLocation = "travelHistory.startDateLocation",
  endDateLocation = "travelHistory.endDateLocation",
  durationLocation = "travelHistory.durationLocation",
  startDateUnload = "travelHistory.startDateUnload",
  endDateUnload = "travelHistory.endDateUnload",
  durationUnload = "travelHistory.durationUnload",
  startDateReturn = "travelHistory.startDateReturn",
  endDateReturn = "travelHistory.endDateReturn",
  durationReturn = "travelHistory.durationReturn"
}


export type TranslationTravelHistoryMessages = {
  [key in TravelHistoryMessages]: string;
}

export const ReportTypesMessages = {
  [ReportTypes.ANALYTIC]: TravelHistoryMessages.analyticReport,
  [ReportTypes.SYNTHETIC]: TravelHistoryMessages.syntheticReport
};
