import * as dateFns from "date-fns";
import { TLanguages } from "../languages/languageData";
import { Duration } from "../interfaces/duration.interface";
import { BasePumpTravelReport, PumpTravelReportCsv, PumpWithMixers, RelatedMixerTravelReport } from "../interfaces/pump-travel-report.interface";

/** Format hour meter in hours and minutes
 * @param hourMeter Hour meter value in minutes
 */
export function formatHourMeter(
  hourMeter?: number
): string {

  if (!hourMeter && hourMeter !== 0) return "";

  const total = (hourMeter / 60);

  const hour = Math.floor(total);
  const decimalPart = total - hour;

  if (decimalPart === 0) return `${hour}h`;

  const minutes = Math.floor(decimalPart * 60);

  if (hour === 0) return `${minutes}m`;

  return `${hour}h ${minutes}m`;
}

/** Format odometer in meter to kilometer
 * @param odometer Odometer value in meter
 * @param appendKM
 * @param decimalSeparator
 */
export function formatOdometer(
  odometer: number,
  appendKM = true,
  decimalSeparator: "." | "," = ","
): string {
  let km = (odometer / 1000).toFixed(1);

  if (decimalSeparator === ",") km = km.replace(".", ",");

  if (appendKM) return `${km} KM`;

  return km;
}

/**
 * Format date and hour
 * @param date Date or Hour or Interval
 * @param type Type of first parameter
 * @param language Language to format date
 */
export function formatDateIfHave(
  date: Duration | Date | string | undefined,
  type: "fullDateWithoutYear" | "fullDateWithoutSeconds" | "fullDateFormat" | "durationDescriptiveTime" | "durationIntervalTime",
  language?: TLanguages
): string {

  // Define formats according to language
  const fullDateWithoutYearFormat = language === "en-US" ? "MM/dd HH:mm:ss" : "dd/MM HH:mm:ss";
  const fullDateWithoutSecondsFormat = language === "en-US" ? "MM/dd/yyyy HH:mm" : "dd/MM/yyyy HH:mm";
  const fullDateFormat = language === "en-US" ? "MM/dd/yyyy HH:mm:ss" : "dd/MM/yyyy HH:mm:ss";

  let intervalDate;

  if (!date) return "";
  if (typeof date === "object") intervalDate = date as Duration;
  else intervalDate = date as Date | string;

  switch (type) {
  case "fullDateWithoutYear":
    return dateFns.format(new Date(intervalDate), fullDateWithoutYearFormat);
  case "fullDateWithoutSeconds":
    return dateFns.format(new Date(intervalDate), fullDateWithoutSecondsFormat);
  case "fullDateFormat":
    return dateFns.format(new Date(intervalDate), fullDateFormat);
  case "durationDescriptiveTime": {
    const duration = date as Duration;
    let formattedTime = "";
    const hours = duration.hours || 0;
    const minutes = duration.minutes || 0;
    const seconds = duration.seconds || 0;

    if (hours > 0) formattedTime += `${hours}h `;
    if (minutes > 0) formattedTime += `${minutes}m `;
    formattedTime += `${seconds}s`;

    return formattedTime;
  }
  case "durationIntervalTime": {

    const initialDate = { hours: "00", minutes: "00", seconds: "00" };

    Object.keys(intervalDate).forEach((key) => initialDate[key] = intervalDate[key].toString().padStart(2, "0"));

    return `${initialDate.hours}:${initialDate.minutes}:${initialDate.seconds}`;
  }
  default:
    return "";
  }
}

/**
 * Calc the duration (range) between two dates
 * @param dateA
 * @param dateB
 */
export function calcDataRange(dateA: Date, dateB: Date) {

  if (!dateA || !dateB) return "";

  const difSeconds = dateFns.differenceInSeconds(new Date(dateB), new Date(dateA));
  const secNum = parseInt(difSeconds.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return { hours, minutes, seconds } as Duration;
}

/**
 * Sum duration array
 * @param durations Array of durations
 */
export function sumDurations(durations: Duration[]): Duration {

  if (!durations || durations.length === 0) return { hours: 0, minutes: 0, seconds: 0 };

  return durations.reduce((acc, cur) => {

    acc.hours = acc.hours ?? 0;
    acc.minutes = acc.minutes ?? 0;
    acc.seconds = acc.seconds ?? 0;

    acc.hours += cur.hours ?? 0;
    acc.minutes += cur.minutes ?? 0;
    acc.seconds += cur.seconds ?? 0;

    if (acc.seconds >= 60) {
      acc.minutes += 1;
      acc.seconds -= 60;
    }

    if (acc.minutes >= 60) {
      acc.hours += 1;
      acc.minutes -= 60;
    }

    return acc;
  }, { hours: 0, minutes: 0, seconds: 0 });
}

/**
 * Groups a list of PumpTravelReportCsv into an array of PumpWithMixers.
 * Each group contains a unique pumpTravel (BasePumpTravelReport)
 * and an array of related mixerTravels (RelatedMixerTravelReport).
 *
 * @param {PumpTravelReportCsv[]} reports - The list of pump and mixer travel reports.
 * @returns {PumpWithMixers[]} - A grouped list where each item has a pumpTravel and its related mixerTravels.
 */
export function groupPumpTravelReports(reports: PumpTravelReportCsv[]): PumpWithMixers[] {
  const map = new Map<string, PumpWithMixers>();

  reports.forEach((report) => {
    // Extract the properties for pumpTravel
    const pumpTravel: BasePumpTravelReport = {
      idTravel: report.idTravel,
      pumpCode: report.pumpCode,
      startDate: report.startDate,
      alerts: report.alerts,
      clientName: report.clientName,
      driverName: report.driverName,
      constructionEntryDate: report.constructionEntryDate,
      constructionLeaveDate: report.constructionLeaveDate,
      durationOnConstruction: report.durationOnConstruction,
      totalPumpingDuration: report.totalPumpingDuration,
      totalIdleDuration: report.totalIdleDuration
    };
    // Create a unique key for the pumpTravel (adjust if necessary)
    const pumpKey = report.idTravel;

    // Check if the key already exists in the map
    if (!map.has(pumpKey)) {
      map.set(pumpKey, {
        ...pumpTravel,
        mixerTravels: []
      });
    }

    // Extract the properties for mixerTravel
    const mixerTravel: RelatedMixerTravelReport = {
      mixerTravelVehicleCode: report.mixerTravelVehicleCode,
      mixerTravelNumDoc: report.mixerTravelNumDoc,
      mixerTravelOnTheWayStartDate: report.mixerTravelOnTheWayStartDate,
      mixerTravelOnTheWayEndDate: report.mixerTravelOnTheWayEndDate,
      mixerTravelOnTheWayDuration: report.mixerTravelOnTheWayDuration,
      mixerTravelOnConstructionStartDate: report.mixerTravelOnConstructionStartDate,
      mixerTravelOnConstructionEndDate: report.mixerTravelOnConstructionEndDate,
      mixerTravelOnConstructionDuration: report.mixerTravelOnConstructionDuration,
      mixerTravelDischargingStartDate: report.mixerTravelDischargingStartDate,
      mixerTravelDischargingEndDate: report.mixerTravelDischargingEndDate,
      mixerTravelDischargingDuration: report.mixerTravelDischargingDuration,
      mixerTravelReturningStartDate: report.mixerTravelReturningStartDate,
      mixerTravelReturningEndDate: report.mixerTravelReturningEndDate,
      mixerTravelReturningDuration: report.mixerTravelReturningDuration
    };

    // Add the mixerTravel to the corresponding pumpTravel group
    map.get(pumpKey)?.mixerTravels.push(mixerTravel);
  });

  // Return the grouped list
  return Array.from(map.values());
}
