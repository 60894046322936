import { VehicleStatesTypesID } from "../../constants/vehicle-states-types.enum";
import { PumpStatusTypes } from "../../constants/pump-timeline-types.enum";

export enum VehiclesStatesMessages {
  atThePlant = "vehiclesStates.atThePlant",
  onTheWay = "vehiclesStates.onTheWay",
  atTheConstructionSite = "vehiclesStates.atTheConstructionSite",
  unloading = "vehiclesStates.unloading",
  returning = "vehiclesStates.returning",
  atDestination = "vehiclesStates.atDestination",
  inTrustedLocation = "vehiclesStates.inTrustedLocation",
  pumping = "vehiclesStates.pumping",
  idle = "vehiclesStates.idle",

  columnStartDate = "vehiclesStates.column.startDate",
  columnEndDate = "vehiclesStates.column.endDate",
  columnDuration = "vehiclesStates.column.duration",
  columnGroup = "vehiclesStates.column.group",
  columnRegional = "vehiclesStates.column.regional",
  columnPlant = "vehiclesStates.column.plant",
  columnVehicleType = "vehiclesStates.column.vehicleType",
  columnVehicle = "vehiclesStates.column.vehicle",
  columnVehicleLicensePlate = "vehiclesStates.column.vehicleLicensePlate",
  columnVehicleLastSendDate = "vehiclesStates.column.vehicleLastSendDate",
  columnVehicleStateType = "vehiclesStates.column.vehicleStateType",
  columnLocation = "vehiclesStates.column.location",
  columnLocationType = "vehiclesStates.column.locationType",
  columnCurrentLatitude = "vehiclesStates.column.currentLatitude",
  columnCurrentLongitude = "vehiclesStates.column.currentLongitude",
  columnCurrentAddress = "vehiclesStates.column.currentAddress",

  // Backend messages
  readVehicleStateHistorySuccess = "vehiclesStates.backend.readVehicleStateHistorySuccess",
  noVehicleStateHistoryFound = "vehiclesStates.backend.noVehicleStateHistoryFound",
  readVehicleStateHistoryError = "vehiclesStates.backend.readVehicleStateHistoryError"
}

export type TranslationVehiclesStatesMessages = {
  [key in VehiclesStatesMessages]: string;
}

export const VehiclesStatesTypesMessages = {
  [VehicleStatesTypesID.NA_USINA]: VehiclesStatesMessages.atThePlant,
  [VehicleStatesTypesID.A_CAMINHO]: VehiclesStatesMessages.onTheWay,
  [VehicleStatesTypesID.EM_OBRA]: VehiclesStatesMessages.atTheConstructionSite,
  [VehicleStatesTypesID.DESCARREGANDO]: VehiclesStatesMessages.unloading,
  [VehicleStatesTypesID.RETORNANDO]: VehiclesStatesMessages.returning,
  [VehicleStatesTypesID.NO_DESTINO]: VehiclesStatesMessages.atDestination,
  [VehicleStatesTypesID.EM_LOCAL_CONFIAVEL]: VehiclesStatesMessages.inTrustedLocation,
  [PumpStatusTypes.BOMBEANDO]: VehiclesStatesMessages.pumping,
  [PumpStatusTypes.OCIOSA]: VehiclesStatesMessages.idle
};
