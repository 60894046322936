import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "En la planta",
  [VehiclesStatesMessages.onTheWay]: "En camino",
  [VehiclesStatesMessages.atTheConstructionSite]: "En la obra",
  [VehiclesStatesMessages.unloading]: "Descargando",
  [VehiclesStatesMessages.returning]: "Regresando",
  [VehiclesStatesMessages.atDestination]: "En el destino",
  [VehiclesStatesMessages.inTrustedLocation]: "En local confiable",
  [VehiclesStatesMessages.pumping]: "Bombeando",
  [VehiclesStatesMessages.idle]: "Ociosa",

  [VehiclesStatesMessages.columnStartDate]: "Inicio",
  [VehiclesStatesMessages.columnEndDate]: "Fin",
  [VehiclesStatesMessages.columnDuration]: "Duración",
  [VehiclesStatesMessages.columnGroup]: "Grupo",
  [VehiclesStatesMessages.columnRegional]: "Regional",
  [VehiclesStatesMessages.columnPlant]: "Planta",
  [VehiclesStatesMessages.columnVehicleType]: "Tipo de vehículo",
  [VehiclesStatesMessages.columnVehicle]: "Vehículo",
  [VehiclesStatesMessages.columnVehicleLicensePlate]: "Placa",
  [VehiclesStatesMessages.columnVehicleLastSendDate]: "Última transmisión",
  [VehiclesStatesMessages.columnVehicleStateType]: "Estado",
  [VehiclesStatesMessages.columnLocation]: "Local",
  [VehiclesStatesMessages.columnLocationType]: "Tipo de local",
  [VehiclesStatesMessages.columnCurrentLatitude]: "Latitud actual",
  [VehiclesStatesMessages.columnCurrentLongitude]: "Longitud actual",
  [VehiclesStatesMessages.columnCurrentAddress]: "Dirección actual",

  [VehiclesStatesMessages.readVehicleStateHistorySuccess]: "Historial de estados de vehículo leído con éxito",
  [VehiclesStatesMessages.noVehicleStateHistoryFound]: "No se encontró historial de estados de vehículo",
  [VehiclesStatesMessages.readVehicleStateHistoryError]: "Error al leer historial de estados de vehículo"
};
